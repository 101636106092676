@import url(//fonts.googleapis.com/css?family=Roboto:300,400,400italic,500,700);
/**
 * Spinners (Loader.CSS)
 */
.loader-primary .ball-pulse > div,
.loader-primary .ball-grid-pulse > div,
.loader-primary .square-spin > div,
.loader-primary .ball-pulse-rise > div,
.loader-primary .ball-rotate > div,
.loader-primary .ball-rotate > div:before, .loader-primary .ball-rotate > div:after,
.loader-primary .cube-transition > div,
.loader-primary .ball-zig-zag > div,
.loader-primary .ball-zig-zag-deflect > div,
.loader-primary .ball-triangle-path > div,
.loader-primary .ball-scale > div,
.loader-primary .line-scale > div,
.loader-primary .line-scale-party > div,
.loader-primary .ball-scale-multiple > div,
.loader-primary .ball-pulse-sync > div,
.loader-primary .ball-beat > div,
.loader-primary .line-scale-pulse-out > div,
.loader-primary .line-scale-pulse-out-rapid > div,
.loader-primary .ball-spin-fade-loader > div,
.loader-primary .line-spin-fade-loader > div,
.loader-primary .triangle-skew-spin > div,
.loader-primary .ball-grid-beat > div,
.loader-primary .semi-circle-spin > div {
  background-color: #dd972d; }

.loader-primary .ball-clip-rotate-multiple {
  width: 1px; }
  .loader-primary .ball-clip-rotate-multiple > div {
    border-color: #dd972d transparent #dd972d transparent; }
    .loader-primary .ball-clip-rotate-multiple > div:last-child {
      border-color: transparent #dd972d transparent #dd972d; }

.loader-primary .ball-clip-rotate > div {
  border-color: #dd972d;
  border-bottom-color: transparent; }

.loader-primary .ball-clip-rotate-pulse > div:first-child {
  background-color: #dd972d;
  top: 5px;
  left: -8px; }

.loader-primary .ball-clip-rotate-pulse > div:last-child {
  border-color: #dd972d transparent #dd972d transparent; }

.loader-primary .square-spin > div,
.loader-primary .ball-scale-ripple > div,
.loader-primary .ball-scale-ripple-multiple > div {
  border-color: #dd972d; }

.loader-primary .pacman > div:first-of-type {
  border-top-color: #dd972d;
  border-left-color: #dd972d;
  border-bottom-color: #dd972d; }

.loader-primary .pacman > div:nth-child(2) {
  border-top-color: #dd972d;
  border-left-color: #dd972d;
  border-bottom-color: #dd972d; }

.loader-primary .pacman > div:nth-child(3), .loader-primary .pacman > div:nth-child(4), .loader-primary .pacman > div:nth-child(5), .loader-primary .pacman > div:nth-child(6) {
  background-color: #dd972d; }

.loader {
  display: inline-block; }

.loader-demo {
  padding: 20px 0;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .loader-demo > .loader-inner {
    display: inline-block; }

.page-loader {
  min-height: 80vh !important; }

/**
 * Main Layout
 */
html, body, #app {
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  min-width: 320px;
  direction: ltr; }

body {
  font-family: "Avant Garde", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #6d6e70;
  font-size: 0.8125rem;
  line-height: 1.5; }

.layout-container {
  position: relative;
  width: 100%;
  height: 100%; }

.page-container, .page-container > div {
  width: 100%;
  height: 100%; }

.main-container {
  position: relative;
  height: calc(100% - 65px);
  overflow: auto;
  -webkit-overflow-scrolling: touch; }
  @media only screen and (min-width: 992px) {
    .main-container {
      margin-left: 0; } }
  .main-container > section, .main-container .section-container {
    min-height: calc(100vh - 60px - 65px); }
  .main-container > div, .main-container > div > div {
    min-height: calc(100% - 65px); }

.content-heading {
  padding: 16px;
  box-shadow: 0 1px 0 rgba(20, 20, 20, 0.075); }

footer {
  left: 0;
  right: 0;
  bottom: 0;
  height: 60px;
  border-top: 1px solid rgba(160, 160, 160, 0.12);
  padding: 20px;
  z-index: 109;
  text-align: center; }

.container-full {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }
  .container-full > .row {
    margin: 0; }
    .container-full > .row > [class*="col-"]:first-child {
      padding-left: 0; }
    .container-full > .row > [class*="col-"]:last-child {
      padding-right: 0; }

.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xs {
  padding: 8px;
  width: auto; }
  @media only screen and (min-width: 480px) {
    .container-fluid,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xs {
      padding: 16px; } }
  @media only screen and (min-width: 992px) {
    .container-fluid,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xs {
      padding: 24px; } }

.container-fluid {
  max-width: none; }

.container-lg {
  max-width: 1200px; }

.container-md {
  max-width: 992px; }

.container-sm {
  max-width: 768px; }

.container-xs {
  max-width: 480px; }

@media only screen and (max-width: 1199px) {
  .container-unwrap {
    padding: 0; }
    .container-unwrap > .row {
      margin: 0; }
      .container-unwrap > .row > [class*="col-"] {
        padding-left: 0;
        padding-right: 0; }
    .container-unwrap .cardbox {
      margin-bottom: 0; } }

.container-overlap {
  position: relative;
  padding: 32px 16px 64px 16px; }
  .container-overlap + .container-fluid,
  .container-overlap + .container-lg,
  .container-overlap + .container-md,
  .container-overlap + .container-sm,
  .container-overlap + .container-xs {
    padding-top: 0;
    margin-top: -32px; }
    .container-overlap + .container-fluid .push-down,
    .container-overlap + .container-lg .push-down,
    .container-overlap + .container-md .push-down,
    .container-overlap + .container-sm .push-down,
    .container-overlap + .container-xs .push-down {
      display: block;
      height: 48px; }
  .container-overlap:before {
    background-color: inherit;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1; }

.main-header {
  font-weight: 400;
  font-size: 24px; }

/**
 * Layout Variants
 */
.sidebar-offcanvas .sidebar-container {
  top: 65px;
  -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0); }
  .sidebar-offcanvas .sidebar-container .sidebar-header {
    box-shadow: 0 0 0 #000; }

.sidebar-offcanvas .header-container {
  z-index: 30; }

.sidebar-offcanvas .main-container,
.sidebar-offcanvas .header-container {
  margin-left: 0; }

.sidebar-offcanvas.offcanvas-visible .sidebar-container {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }

.no-csstransforms3d .sidebar-offcanvas .sidebar-offcanvas .sidebar-container {
  margin-left: 0; }

.no-csstransforms3d .sidebar-offcanvas .sidebar-offcanvas.offcanvas-visible .sidebar-container {
  margin-left: 0; }

/**
 * Generation
 */
.header-container {
  position: relative;
  background-color: #fff;
  z-index: 100;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.14); }
  @media only screen and (min-width: 992px) {
    .header-container {
      margin-left: 0; } }
  .header-container nav {
    padding: 0; }
    .header-container nav .navbar-brand > img {
      height: calc(65px - 0.69531rem); }
    .header-container nav ul.navbar-nav > li {
      padding-top: 0;
      padding-bottom: 0; }
      .header-container nav ul.navbar-nav > li > a {
        display: flex;
        align-items: center;
        height: 100%; }
        @media only screen and (max-width: 767px) {
          .header-container nav ul.navbar-nav > li > a {
            white-space: normal; } }
        @media (max-width: 991.98px) {
          .header-container nav ul.navbar-nav > li > a {
            max-width: 100%; } }
        .header-container nav ul.navbar-nav > li > a > em {
          font-size: 24px; }
        .header-container nav ul.navbar-nav > li > a .icon {
          margin-right: 0.25em; }
        .header-container nav ul.navbar-nav > li > a .dropdown-icon {
          margin-left: 0.25em; }
        .header-container nav ul.navbar-nav > li > a:hover {
          background-color: rgba(0, 0, 0, 0.025); }
        .header-container nav ul.navbar-nav > li > a.active-premise {
          padding-left: 1rem;
          padding-right: 1rem;
          font-size: 15px; }
          @media (max-width: 991.98px) {
            .header-container nav ul.navbar-nav > li > a.active-premise {
              padding-left: 0;
              padding-right: 0; } }
      @media only screen and (max-width: 767px) {
        .header-container nav ul.navbar-nav > li .dropdown-menu .dropdown-item {
          white-space: normal;
          line-height: 1.2;
          padding: 0.5rem 1rem; } }

.layout-container {
  position: relative;
  width: 100%;
  height: 100%; }
  @media only screen and (max-width: 991px) {
    .layout-container .header-container .navbar-brand > img {
      -webkit-transition: all 0.5s ease-out 0s;
      transition: all 0.5s ease-out 0s; }
    .layout-container .header-container .navbar-toggler-icon {
      -webkit-transition: all 0.5s ease-out 0s;
      transition: all 0.5s ease-out 0s; }
    .layout-container.scrolled .header-container .navbar-brand > img {
      height: calc(40px - 0.34766rem); }
    .layout-container.scrolled .header-container .navbar-toggler-icon {
      height: 1.2em;
      width: 1.2em; } }

/**
 * Material Forms
 */
.mda-form-group {
  position: relative;
  padding: 0 0 24px 0;
  flex: 1 1 auto; }

.mda-form-control {
  position: relative;
  padding-top: 18px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);
  flex: 1 1 auto; }

.mda-form-control > input, .mda-form-control > textarea, .mda-form-control > select {
  padding: 2px;
  height: 34px;
  background-color: transparent !important;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  width: 100% !important; }
  .mda-form-control > input:not([value=""]) ~ label, .mda-form-control > input:focus ~ label, .mda-form-control > input.focus ~ label, .mda-form-control > textarea:not([value=""]) ~ label, .mda-form-control > textarea:focus ~ label, .mda-form-control > textarea.focus ~ label, .mda-form-control > select:not([value=""]) ~ label, .mda-form-control > select:focus ~ label, .mda-form-control > select.focus ~ label {
    top: 0 !important;
    font-size: 0.85em !important;
    color: #dd972d;
    opacity: 1; }
  .mda-form-control > input ~ label, .mda-form-control > textarea ~ label, .mda-form-control > select ~ label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    display: inline-block;
    font-size: .85em;
    opacity: .5;
    font-weight: 500;
    -webkit-transition: all .2s ease;
    transition: all .2s ease; }
  .mda-form-control > input.disabled, .mda-form-control > input[disabled], .mda-form-control > textarea.disabled, .mda-form-control > textarea[disabled], .mda-form-control > select.disabled, .mda-form-control > select[disabled] {
    background-color: transparent;
    opacity: .5;
    cursor: not-allowed; }

.mda-form-control.mda-form-control-dark {
  border-bottom-color: rgba(255, 255, 255, 0.26); }
  .mda-form-control.mda-form-control-dark > input, .mda-form-control.mda-form-control-dark > textarea, .mda-form-control.mda-form-control-dark > select {
    color: #fff; }
    .mda-form-control.mda-form-control-dark > input:focus ~ label,
    .mda-form-control.mda-form-control-dark > input.focus ~ label, .mda-form-control.mda-form-control-dark > textarea:focus ~ label,
    .mda-form-control.mda-form-control-dark > textarea.focus ~ label, .mda-form-control.mda-form-control-dark > select:focus ~ label,
    .mda-form-control.mda-form-control-dark > select.focus ~ label {
      color: #fff !important; }

.mda-form-group-lg .mda-form-control > input {
  height: 48px; }

.mda-form-group-lg.float-label .mda-form-control > input ~ label {
  top: 28px;
  font-size: 1.5em; }

.mda-form-control > textarea {
  height: auto; }

.mda-form-control-line {
  position: absolute;
  bottom: -1px;
  left: 50%;
  right: 50%;
  height: 2px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease; }

.mda-form-control > input:focus ~ .mda-form-control-line, .mda-form-control > textarea:focus ~ .mda-form-control-line, .mda-form-control > select:focus ~ .mda-form-control-line {
  background-color: #dd972d;
  left: 0;
  right: 0; }

.mda-form-control.mda-form-control-dark > input:focus ~ .mda-form-control-line, .mda-form-control.mda-form-control-dark > textarea:focus ~ .mda-form-control-line, .mda-form-control.mda-form-control-dark > select:focus ~ .mda-form-control-line {
  background-color: #fff; }

.mda-form-msg {
  position: absolute;
  bottom: 0;
  font-size: .85em;
  line-height: 24px;
  font-weight: 300;
  color: #90A4AE; }
  .mda-form-msg.right {
    right: 0; }

.form-validate .mda-form-control .form-control.error ~ .mda-form-control-line {
  background-color: #F44336; }

.form-validate .mda-form-control .form-control.error:focus ~ label,
.form-validate .mda-form-control .form-control.error.focus ~ label,
.form-validate .mda-form-control .form-control.error ~ .mda-form-msg {
  color: #F44336; }

.form-validate .mda-form-control .form-control.valid ~ .mda-form-control-line,
.form-validate .mda-form-control .form-control.valid:focus ~ .mda-form-control-line {
  background-color: #4CAF50; }

.form-validate .mda-form-control .form-control.valid:focus ~ label,
.form-validate .mda-form-control .form-control.valid.focus ~ label,
.form-validate .mda-form-control .form-control.valid:focus:focus ~ label,
.form-validate .mda-form-control .form-control.valid:focus.focus ~ label {
  color: #4CAF50; }

.form-validate label.error {
  color: #F44336; }

.form-validate label.valid {
  color: #4CAF50; }

.float-label .mda-form-control > input ~ label,
.float-label .mda-form-control > textarea ~ label {
  top: 15px;
  margin-bottom: 0;
  font-size: 1em;
  pointer-events: none; }

.float-label .mda-form-control > input.valid ~ label,
.float-label .mda-form-control > input.error ~ label,
.float-label .mda-form-control > input.has-value ~ label,
.float-label .mda-form-control > textarea.valid ~ label,
.float-label .mda-form-control > textarea.error ~ label,
.float-label .mda-form-control > textarea.has-value ~ label {
  top: 0;
  font-size: .85em; }

.mda-input-group {
  position: relative; }
  .mda-input-group .mda-form-control > input {
    padding-left: 36px; }
  .mda-input-group .mda-form-control > label {
    margin-left: 36px; }
  .mda-input-group .form-control {
    float: none; }
  .mda-input-group > .mda-input-group-addon {
    position: absolute;
    top: 15px;
    left: 2px; }
  .mda-input-group .mda-form-group + .input-group-btn,
  .mda-input-group .input-group-btn + .mda-form-group {
    display: block;
    padding-left: 16px; }

.mda-checkbox,
.mda-radio {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin: 0;
  cursor: pointer; }
  .mda-checkbox input,
  .mda-radio input {
    position: absolute;
    z-index: 1;
    width: 20px;
    height: 20px;
    margin-left: -20px;
    cursor: pointer;
    opacity: 0; }
    .mda-checkbox input:checked + em:before,
    .mda-radio input:checked + em:before {
      background-color: inherit;
      border-width: 0; }
    .mda-checkbox input:checked + span .active,
    .mda-radio input:checked + span .active {
      display: inherit; }
  .mda-checkbox input[type="checkbox"]:checked + em:after,
  .mda-radio input[type="checkbox"]:checked + em:after {
    position: absolute;
    top: 2px;
    left: 6px;
    display: table;
    width: 6px;
    height: 12px;
    border: 2px solid;
    border-top: 0;
    border-left: 0;
    content: ' ';
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .mda-checkbox input[type="radio"]:checked + em:after,
  .mda-radio input[type="radio"]:checked + em:after {
    content: ' ';
    position: absolute;
    top: 3px;
    left: 3px;
    display: table;
    width: 12px;
    height: 12px;
    background: inherit;
    border-radius: 50%;
    border: 2px solid #fff; }
  .mda-checkbox input[type="radio"] + em,
  .mda-checkbox input[type="radio"] + em:before,
  .mda-radio input[type="radio"] + em,
  .mda-radio input[type="radio"] + em:before {
    border-radius: 50%; }

.mda-checkbox input[disabled] + em:before,
fieldset[disabled] .mda-checkbox input + em:before,
.mda-radio input[disabled] + em:before,
fieldset[disabled] .mda-radio input + em:before {
  border-color: rgba(0, 0, 0, 0.26); }

.mda-checkbox input[disabled]:checked + em,
.mda-radio input[disabled]:checked + em {
  background-color: rgba(0, 0, 0, 0.26); }

.mda-radio input[disabled]:checked + em:after {
  background-color: transparent; }

.mda-checkbox input[disabled]:checked + em:before {
  background-color: rgba(0, 0, 0, 0.26); }

.mda-checkbox > em,
.mda-radio > em {
  position: relative;
  display: inline-block;
  width: 0;
  height: 18px;
  margin-top: -2px;
  margin-right: 28px;
  margin-left: -20px;
  line-height: 1;
  vertical-align: middle; }
  .mda-checkbox > em:before,
  .mda-radio > em:before {
    content: "";
    position: absolute;
    width: 18px;
    height: 100%;
    border: 2px solid rgba(108, 117, 125, 0.56);
    border-radius: 2px;
    -webkit-transition: all .2s;
    transition: all .2s; }
  .mda-checkbox > em.empty:after,
  .mda-radio > em.empty:after {
    display: none !important; }

/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #dd972d;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #f4f4f4; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #dd972d;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #a36d1a;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.03125rem; }

h2, .h2 {
  font-size: 1.625rem; }

h3, .h3 {
  font-size: 1.42188rem; }

h4, .h4 {
  font-size: 1.21875rem; }

h5, .h5 {
  font-size: 1.01562rem; }

h6, .h6 {
  font-size: 0.8125rem; }

.lead {
  font-size: 1.01562rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.01562rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f4f4f4;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid rgba(108, 117, 125, 0.32); }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid rgba(108, 117, 125, 0.32); }
  .table tbody + tbody {
    border-top: 2px solid rgba(108, 117, 125, 0.32); }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid rgba(108, 117, 125, 0.32); }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid rgba(108, 117, 125, 0.32); }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #f5e2c4; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #edc992; }

.table-hover .table-primary:hover {
  background-color: #f1d7ae; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #f1d7ae; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: rgba(108, 117, 125, 0.32); }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      -webkit-transition: none;
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #efcd9b;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(221, 151, 45, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.01562rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.71094rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.71094rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.01562rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.71094rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.71094rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      -webkit-transition: none;
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(221, 151, 45, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #212529;
  background-color: #dd972d;
  border-color: #dd972d; }
  .btn-primary:hover {
    color: #fff;
    background-color: #c48320;
    border-color: #b97b1e; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(193, 134, 44, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #212529;
    background-color: #dd972d;
    border-color: #dd972d; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #b97b1e;
    border-color: #ae741c; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(193, 134, 44, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #dd972d;
  border-color: #dd972d; }
  .btn-outline-primary:hover {
    color: #212529;
    background-color: #dd972d;
    border-color: #dd972d; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(221, 151, 45, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #dd972d;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #212529;
    background-color: #dd972d;
    border-color: #dd972d; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(221, 151, 45, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #dd972d;
  text-decoration: none; }
  .btn-link:hover {
    color: #a36d1a;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.01562rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.71094rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      -webkit-transition: none;
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      -webkit-transition: none;
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.8125rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #dd972d; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.71094rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.01562rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.71094rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.21875rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #dd972d;
    background-color: #dd972d; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(221, 151, 45, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #efcd9b; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #f6e3c7;
    border-color: #f6e3c7; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.10938rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.10938rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #dd972d;
  background-color: #dd972d; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(221, 151, 45, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(221, 151, 45, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(221, 151, 45, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.10938rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        -webkit-transition: none;
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
            transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(221, 151, 45, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #efcd9b;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(221, 151, 45, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.71094rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.01562rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #efcd9b;
    box-shadow: 0 0 0 0.2rem rgba(221, 151, 45, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #f4f4f4, 0 0 0 0.2rem rgba(221, 151, 45, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #f4f4f4, 0 0 0 0.2rem rgba(221, 151, 45, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #f4f4f4, 0 0 0 0.2rem rgba(221, 151, 45, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #dd972d;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #f6e3c7; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #dd972d;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
         appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #f6e3c7; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #dd972d;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #f6e3c7; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      -webkit-transition: none;
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #f4f4f4;
    border-color: #dee2e6 #dee2e6 #f4f4f4; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #dd972d; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.34766rem;
  padding-bottom: 0.34766rem;
  margin-right: 1rem;
  font-size: 1.01562rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.01562rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 1rem;
      padding-left: 1rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: #000; }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: #000; }

.navbar-light .navbar-nav .nav-link {
  color: #000; }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: #000; }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.5); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #000; }

.navbar-light .navbar-toggler {
  color: #000;
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='%23000' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: #000; }
  .navbar-light .navbar-text a {
    color: #000; }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: #000; }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
       -moz-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #dd972d;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #a36d1a;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(221, 151, 45, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #dd972d;
  border-color: #dd972d; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.01562rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.71094rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      -webkit-transition: none;
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #212529;
  background-color: #dd972d; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #212529;
    background-color: #b97b1e; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(221, 151, 45, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3.71875rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #734f17;
  background-color: #f8ead5;
  border-color: #f5e2c4; }
  .alert-primary hr {
    border-top-color: #f1d7ae; }
  .alert-primary .alert-link {
    color: #49320f; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.60938rem;
  background-color: #f1f1f1;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #dd972d;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      -webkit-transition: none;
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #dd972d;
    border-color: #dd972d; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #734f17;
  background-color: #f5e2c4; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #734f17;
    background-color: #f1d7ae; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #734f17;
    border-color: #734f17; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.21875rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        -webkit-transition: none;
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid rgba(108, 117, 125, 0.32);
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid rgba(108, 117, 125, 0.32);
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.71094rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.71094rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.8125rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      -webkit-transition: none;
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  -webkit-transition: 0s 0.6s opacity;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      -webkit-transition: none;
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      -webkit-transition: none;
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    -webkit-transition: opacity 0.6s ease;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        -webkit-transition: none;
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
          animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
          animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #dd972d !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #b97b1e !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid rgba(108, 117, 125, 0.32) !important; }

.border-top {
  border-top: 1px solid rgba(108, 117, 125, 0.32) !important; }

.border-right {
  border-right: 1px solid rgba(108, 117, 125, 0.32) !important; }

.border-bottom {
  border-bottom: 1px solid rgba(108, 117, 125, 0.32) !important; }

.border-left {
  border-left: 1px solid rgba(108, 117, 125, 0.32) !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #dd972d !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #dd972d !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #a36d1a !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: rgba(108, 117, 125, 0.32); }
  .table .thead-dark th {
    color: inherit;
    border-color: rgba(108, 117, 125, 0.32); } }

/**
 * Ripple
 */
.ripple {
  position: relative;
  overflow: hidden; }
  .ripple > .md-ripple {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    bottom: 0;
    right: 0;
    overflow: hidden;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 50%;
    -webkit-transform: scale(0.0001, 0.0001);
            transform: scale(0.0001, 0.0001);
    opacity: 1;
    width: 0;
    height: 0; }
    .ripple > .md-ripple.md-ripple-animate {
      -webkit-transition: -webkit-transform 0.5s cubic-bezier(0, 0, 0.2, 1), opacity 0.6s cubic-bezier(0, 0, 0.2, 1);
      -webkit-transition: opacity 0.6s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.5s cubic-bezier(0, 0, 0.2, 1);
      transition: opacity 0.6s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.5s cubic-bezier(0, 0, 0.2, 1);
      transition: transform 0.5s cubic-bezier(0, 0, 0.2, 1), opacity 0.6s cubic-bezier(0, 0, 0.2, 1);
      transition: transform 0.5s cubic-bezier(0, 0, 0.2, 1), opacity 0.6s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.5s cubic-bezier(0, 0, 0.2, 1);
      opacity: 0;
      -webkit-transform: scale(2);
              transform: scale(2); }
  .ripple.btn-oval > .md-ripple.md-ripple-animate, .ripple.btn-pill-left > .md-ripple.md-ripple-animate, .ripple.btn-pill-right > .md-ripple.md-ripple-animate, .ripple.btn-flat-icon > .md-ripple.md-ripple-animate, .ripple.btn-circle > .md-ripple.md-ripple-animate {
    -webkit-transform: scale(0.99);
            transform: scale(0.99); }

/**
 * Bootstrap Reset
 */
*:focus {
  outline: 0 !important; }

select, input, textarea {
  color: inherit;
  font: inherit; }

a, .btn-link {
  outline: none !important;
  color: #dd972d;
  -webkit-transition: color .2s ease;
  transition: color .2s ease; }
  a.text-muted:hover, a.text-muted:focus, .btn-link.text-muted:hover, .btn-link.text-muted:focus {
    color: #545b62 !important; }

address {
  margin-bottom: 2rem; }

small {
  font-size: 85%; }

.rounded {
  border-radius: 6px !important; }

.text-muted {
  color: #6c757d !important; }

hr {
  border-top: 1px solid rgba(108, 117, 125, 0.36);
  margin-top: 2rem;
  margin-bottom: 2rem; }

code {
  margin-left: 1px;
  margin-right: 1px;
  -webkit-font-smoothing: auto;
  padding: 0.125em 0.35em;
  border-radius: 2px;
  font-size: inherit;
  background: rgba(0, 0, 0, 0.045);
  color: #106CC8; }

pre {
  background-color: rgba(108, 117, 125, 0.12);
  border-color: rgba(108, 117, 125, 0.26);
  color: inherit; }

.img-thumbnail {
  border-radius: 1px;
  border: 0;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.098), 0 1px 10px 0 rgba(0, 0, 0, 0.014); }

.page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid rgba(108, 117, 125, 0.06); }

.breadcrumb {
  font-weight: normal;
  border-radius: 0;
  color: #6c757d;
  padding: 20px 20px;
  background-color: #fff;
  border-bottom: 1px solid rgba(108, 117, 125, 0.16);
  margin-bottom: 2rem; }
  .breadcrumb .breadcrumb-item::before {
    color: #ccc; }

.btn {
  outline: none !important;
  border-radius: 2px;
  -webkit-transition-property: background-color, color, box-shadow;
  transition-property: background-color, color, box-shadow;
  -webkit-transition-duration: .1s, .2s, .2s;
          transition-duration: .1s, .2s, .2s;
  -webkit-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out;
  font-weight: 400;
  line-height: 1.25rem;
  font-family: inherit;
  padding: .375rem .75rem;
  font-size: .875rem; }
  .btn:active, .btn.active {
    box-shadow: 0 0 0 #000; }

.btn-group-sm > .btn, .btn-sm {
  padding: 0.3125rem 0.625rem;
  font-size: 0.75rem;
  line-height: 0.25rem;
  border-radius: 0.2rem;
  line-height: 1.5; }

.btn-lg {
  padding: 0.625rem 1rem;
  font-size: 1.125rem;
  line-height: 1.3;
  border-radius: 0.2rem;
  line-height: 1.34; }

.btn-xs {
  padding: 1px 5px;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 2px;
  line-height: 1.5; }

.btn-secondary {
  color: #212529;
  background-color: #fff;
  border-color: #e8e8e8; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #6d6e70;
    border-color: #cfcfcf; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(202, 203, 203, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #fff;
    border-color: #e8e8e8; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #c8c8c8; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(202, 203, 203, 0.5); }

/*
.btn-primary   { @include button-variant($brand-primary, $brand-primary, #fff); }
.btn-success   { @include button-variant($brand-success, $brand-success, #fff); }
.btn-info      { @include button-variant($brand-info, $brand-info, #fff); }
.btn-warning   { @include button-variant($brand-warning, $brand-warning, #fff); }
.btn-danger    { @include button-variant($brand-danger, $brand-danger, #fff); }*/
.btn-warning:hover {
  color: #fff; }

.btn-outline-secondary {
  color: #6d6e70;
  border-color: #6d6e70;
  border-color: #ccd4dc; }
  .btn-outline-secondary:hover {
    color: #ccd4dc;
    background-color: #6d6e70;
    border-color: #ccd4dc; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(109, 110, 112, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6d6e70;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6d6e70;
    border-color: #ccd4dc; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(109, 110, 112, 0.5); }

.text-primary {
  color: #dd972d !important; }

.text-success {
  color: #4CAF50 !important; }

.text-info {
  color: #03A9F4 !important; }

.text-warning {
  color: #FFB300 !important; }

.text-danger {
  color: #F44336 !important; }

.pagination > .page-item > .page-link {
  color: inherit;
  background-color: rgba(240, 240, 240, 0.06);
  border-color: rgba(108, 117, 125, 0.16); }

.pagination > .page-item > .page-link:focus,
.pagination > .page-item > .page-link:hover {
  background-color: #dd972d;
  border-color: #dd972d;
  color: #fff; }

.pagination > .active > .page-link,
.pagination > .active > .page-link:focus,
.pagination > .active > .page-link:hover {
  background-color: #dd972d;
  border-color: #dd972d;
  color: #fff; }

.pagination > .disabled {
  cursor: not-allowed; }
  .pagination > .disabled > .page-link,
  .pagination > .disabled > .page-link:hover,
  .pagination > .disabled > .page-link:focus {
    color: #777;
    background-color: rgba(240, 240, 240, 0.06);
    border-color: rgba(108, 117, 125, 0.16); }

.tag {
  display: inline;
  padding: 2px 6px 3px;
  font-size: .625rem;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem; }

.tag-primary {
  background-color: #dd972d; }

.tag-info {
  background-color: #03A9F4; }

.tag-success {
  background-color: #4CAF50; }

.tag-warning {
  background-color: #FFB300; }

.tag-danger {
  background-color: #F44336; }

.alert-primary {
  color: #fff;
  background-color: #dd972d;
  border-color: #dd972d; }
  .alert-primary hr {
    border-top-color: #cf8a22; }
  .alert-primary .alert-link {
    color: #e6e6e6; }
  .alert-primary a, .alert-primary .alert-link {
    color: rgba(255, 255, 255, 0.58); }

.alert-success {
  color: #fff;
  background-color: #4CAF50;
  border-color: #4CAF50; }
  .alert-success hr {
    border-top-color: #449d48; }
  .alert-success .alert-link {
    color: #e6e6e6; }
  .alert-success a, .alert-success .alert-link {
    color: rgba(255, 255, 255, 0.58); }

.alert-info {
  color: #fff;
  background-color: #03A9F4;
  border-color: #03A9F4; }
  .alert-info hr {
    border-top-color: #0398db; }
  .alert-info .alert-link {
    color: #e6e6e6; }
  .alert-info a, .alert-info .alert-link {
    color: rgba(255, 255, 255, 0.58); }

.alert-warning {
  color: #fff;
  background-color: #FFB300;
  border-color: #FFB300; }
  .alert-warning hr {
    border-top-color: #e6a100; }
  .alert-warning .alert-link {
    color: #e6e6e6; }
  .alert-warning a, .alert-warning .alert-link {
    color: rgba(255, 255, 255, 0.58); }

.alert-danger {
  color: #fff;
  background-color: #F44336;
  border-color: #F44336; }
  .alert-danger hr {
    border-top-color: #f32c1e; }
  .alert-danger .alert-link {
    color: #e6e6e6; }
  .alert-danger a, .alert-danger .alert-link {
    color: rgba(255, 255, 255, 0.58); }

.alert-dismissable .close, .alert-dismissible .close {
  color: rgba(0, 0, 0, 0.75); }

.list-group .list-group-item {
  color: inherit;
  background-color: transparent;
  border-color: rgba(108, 117, 125, 0.12);
  padding: 1rem 1.5rem; }
  .list-group .list-group-item.active {
    color: #fff; }
  .list-group .list-group-item.disabled, .list-group .list-group-item.disabled:focus, .list-group .list-group-item.disabled:hover {
    background-color: rgba(108, 117, 125, 0.12);
    color: inherit; }
  .card > .list-group .list-group-item {
    border-top: 0; }

a.list-group-item:focus,
a.list-group-item:hover,
button.list-group-item:focus,
button.list-group-item:hover {
  background-color: transparent;
  color: #dd972d; }

.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
  background-color: #03A9F4;
  border-color: #03A9F4; }

.card {
  border-radius: 3px;
  border: 1px solid rgba(108, 117, 125, 0.16);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); }
  .card .card-header {
    background: transparent;
    padding: 16px 24px;
    font-weight: 500;
    border-bottom: 1px solid rgba(108, 117, 125, 0.16); }
  .card .card-body {
    padding: 1.5rem; }
  .card .card-footer {
    background-color: rgba(108, 117, 125, 0.1);
    border-color: rgba(108, 117, 125, 0.1); }

.card-accordion .card-header a {
  color: inherit;
  text-decoration: none;
  font-size: 1rem; }
  .card-accordion .card-header a:hover, .card-accordion .card-header a:focus {
    color: #dd972d; }

.card-accordion .card .card-header {
  padding: 12px 24px; }

.card-accordion .card .card-title a {
  text-decoration: none; }

.card-default .card-header {
  background-color: rgba(108, 117, 125, 0.075); }

.card-inverse .text-muted {
  color: rgba(255, 255, 255, 0.5) !important; }

.well {
  border: 1px solid rgba(108, 117, 125, 0.16);
  background-color: #fff; }

.jumbotron {
  padding: 1.5rem 3rem;
  border: 1px solid rgba(108, 117, 125, 0.12);
  background-color: #fff; }
  .jumbotron h1 {
    font-size: 3.9375rem; }
  .jumbotron p {
    font-weight: 300;
    font-size: 1.3125rem;
    margin-bottom: 1rem; }
  @media only screen and (min-width: 768px) {
    .jumbotron {
      padding: 1.875rem 3.75rem; } }

.nav-tabs {
  padding-bottom: 1px;
  border-bottom: 0; }

.nav-tabs > .nav-item > .nav-link,
.nav-tabs.nav-justified > .nav-item > .nav-link {
  padding: 16px 24px;
  background-color: transparent;
  border-radius: 0;
  border: 0;
  color: inherit; }
  .nav-tabs > .nav-item > .nav-link:hover, .nav-tabs > .nav-item > .nav-link:focus,
  .nav-tabs.nav-justified > .nav-item > .nav-link:hover,
  .nav-tabs.nav-justified > .nav-item > .nav-link:focus {
    border-bottom-color: rgba(108, 117, 125, 0.36);
    background-color: transparent;
    color: inherit; }

.nav-tabs.nav-justified.nav-item > .nav-link {
  margin-bottom: 2px;
  border-bottom-color: rgba(108, 117, 125, 0.16); }

.nav-tabs > .nav-item > .nav-link.active, .nav-tabs > .nav-item > .nav-link.active:hover, .nav-tabs > .nav-item > .nav-link.active:focus {
  color: #dd972d; }

.nav-tabs > .nav-item > .nav-link.active, .nav-tabs > .nav-item > .nav-link.active:focus, .nav-tabs > .nav-item > .nav-link.active:hover,
.nav-tabs.nav-justified > .nav-item > .active, .nav-tabs.nav-justified > .nav-item > .active:hover, .nav-tabs.nav-justified > .nav-item > .active:focus {
  border: 0;
  border-bottom: 2px solid;
  background-color: transparent; }

.tab-content {
  padding: 10px 20px; }

.nav-pills > .nav-item > .nav-link.active,
.nav-pills > .nav-item > .nav-link.active:focus,
.nav-pills > .nav-item > .nav-link.active:hover {
  background-color: #dd972d; }

.nav-pills > .nav-item + .nav-item {
  margin-left: 5px; }

.nav > .nav-item > .nav-link:focus, .nav > .nav-item > .nav-link:hover {
  background-color: rgba(108, 117, 125, 0.12); }

.form-control {
  height: calc(2rem + 2px);
  padding: .375rem .75rem;
  box-shadow: 0 0 0 #000 !important;
  font-size: inherit; }
  .form-control.form-control-lg {
    height: calc(2.875rem + 2px);
    padding: .5rem 1rem; }
  .form-control:focus {
    border-color: #aaa; }

.form-check-input {
  margin-right: .75rem; }

.input-group-prepend,
.input-group-append,
.input-group-text,
.custom-file-label,
.custom-file-label::after {
  height: calc(2rem + 2px);
  font-size: inherit; }

.custom-control-label {
  line-height: 1.75; }

@media only screen and (max-width: 1199px) {
  input[type="text"], input[type="email"], input[type="search"], input[type="password"] {
    -webkit-appearance: none; } }

.has-success .success-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #4CAF50; }

.has-success .success-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.71094rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(76, 175, 80, 0.9);
  border-radius: 0.25rem; }

.was-validated .has-success .form-control:success, .has-success .form-control.is-success {
  border-color: #4CAF50; }
  .was-validated .has-success .form-control:success:focus, .has-success .form-control.is-success:focus {
    border-color: #4CAF50;
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25); }
  .was-validated .has-success .form-control:success ~ .success-feedback,
  .was-validated .has-success .form-control:success ~ .success-tooltip, .has-success .form-control.is-success ~ .success-feedback,
  .has-success .form-control.is-success ~ .success-tooltip {
    display: block; }

.was-validated .has-success .custom-select:success, .has-success .custom-select.is-success {
  border-color: #4CAF50; }
  .was-validated .has-success .custom-select:success:focus, .has-success .custom-select.is-success:focus {
    border-color: #4CAF50;
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25); }
  .was-validated .has-success .custom-select:success ~ .success-feedback,
  .was-validated .has-success .custom-select:success ~ .success-tooltip, .has-success .custom-select.is-success ~ .success-feedback,
  .has-success .custom-select.is-success ~ .success-tooltip {
    display: block; }

.was-validated .has-success .form-control-file:success ~ .success-feedback,
.was-validated .has-success .form-control-file:success ~ .success-tooltip, .has-success .form-control-file.is-success ~ .success-feedback,
.has-success .form-control-file.is-success ~ .success-tooltip {
  display: block; }

.was-validated .has-success .form-check-input:success ~ .form-check-label, .has-success .form-check-input.is-success ~ .form-check-label {
  color: #4CAF50; }

.was-validated .has-success .form-check-input:success ~ .success-feedback,
.was-validated .has-success .form-check-input:success ~ .success-tooltip, .has-success .form-check-input.is-success ~ .success-feedback,
.has-success .form-check-input.is-success ~ .success-tooltip {
  display: block; }

.was-validated .has-success .custom-control-input:success ~ .custom-control-label, .has-success .custom-control-input.is-success ~ .custom-control-label {
  color: #4CAF50; }
  .was-validated .has-success .custom-control-input:success ~ .custom-control-label::before, .has-success .custom-control-input.is-success ~ .custom-control-label::before {
    border-color: #4CAF50; }

.was-validated .has-success .custom-control-input:success ~ .success-feedback,
.was-validated .has-success .custom-control-input:success ~ .success-tooltip, .has-success .custom-control-input.is-success ~ .success-feedback,
.has-success .custom-control-input.is-success ~ .success-tooltip {
  display: block; }

.was-validated .has-success .custom-control-input:success:checked ~ .custom-control-label::before, .has-success .custom-control-input.is-success:checked ~ .custom-control-label::before {
  border-color: #6ec071;
  background-color: #6ec071; }

.was-validated .has-success .custom-control-input:success:focus ~ .custom-control-label::before, .has-success .custom-control-input.is-success:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25); }

.was-validated .has-success .custom-control-input:success:focus:not(:checked) ~ .custom-control-label::before, .has-success .custom-control-input.is-success:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #4CAF50; }

.was-validated .has-success .custom-file-input:success ~ .custom-file-label, .has-success .custom-file-input.is-success ~ .custom-file-label {
  border-color: #4CAF50; }

.was-validated .has-success .custom-file-input:success ~ .success-feedback,
.was-validated .has-success .custom-file-input:success ~ .success-tooltip, .has-success .custom-file-input.is-success ~ .success-feedback,
.has-success .custom-file-input.is-success ~ .success-tooltip {
  display: block; }

.was-validated .has-success .custom-file-input:success:focus ~ .custom-file-label, .has-success .custom-file-input.is-success:focus ~ .custom-file-label {
  border-color: #4CAF50;
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25); }

.has-warning .warning-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #FFB300; }

.has-warning .warning-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.71094rem;
  line-height: 1.5;
  color: #212529;
  background-color: rgba(255, 179, 0, 0.9);
  border-radius: 0.25rem; }

.was-validated .has-warning .form-control:warning, .has-warning .form-control.is-warning {
  border-color: #FFB300; }
  .was-validated .has-warning .form-control:warning:focus, .has-warning .form-control.is-warning:focus {
    border-color: #FFB300;
    box-shadow: 0 0 0 0.2rem rgba(255, 179, 0, 0.25); }
  .was-validated .has-warning .form-control:warning ~ .warning-feedback,
  .was-validated .has-warning .form-control:warning ~ .warning-tooltip, .has-warning .form-control.is-warning ~ .warning-feedback,
  .has-warning .form-control.is-warning ~ .warning-tooltip {
    display: block; }

.was-validated .has-warning .custom-select:warning, .has-warning .custom-select.is-warning {
  border-color: #FFB300; }
  .was-validated .has-warning .custom-select:warning:focus, .has-warning .custom-select.is-warning:focus {
    border-color: #FFB300;
    box-shadow: 0 0 0 0.2rem rgba(255, 179, 0, 0.25); }
  .was-validated .has-warning .custom-select:warning ~ .warning-feedback,
  .was-validated .has-warning .custom-select:warning ~ .warning-tooltip, .has-warning .custom-select.is-warning ~ .warning-feedback,
  .has-warning .custom-select.is-warning ~ .warning-tooltip {
    display: block; }

.was-validated .has-warning .form-control-file:warning ~ .warning-feedback,
.was-validated .has-warning .form-control-file:warning ~ .warning-tooltip, .has-warning .form-control-file.is-warning ~ .warning-feedback,
.has-warning .form-control-file.is-warning ~ .warning-tooltip {
  display: block; }

.was-validated .has-warning .form-check-input:warning ~ .form-check-label, .has-warning .form-check-input.is-warning ~ .form-check-label {
  color: #FFB300; }

.was-validated .has-warning .form-check-input:warning ~ .warning-feedback,
.was-validated .has-warning .form-check-input:warning ~ .warning-tooltip, .has-warning .form-check-input.is-warning ~ .warning-feedback,
.has-warning .form-check-input.is-warning ~ .warning-tooltip {
  display: block; }

.was-validated .has-warning .custom-control-input:warning ~ .custom-control-label, .has-warning .custom-control-input.is-warning ~ .custom-control-label {
  color: #FFB300; }
  .was-validated .has-warning .custom-control-input:warning ~ .custom-control-label::before, .has-warning .custom-control-input.is-warning ~ .custom-control-label::before {
    border-color: #FFB300; }

.was-validated .has-warning .custom-control-input:warning ~ .warning-feedback,
.was-validated .has-warning .custom-control-input:warning ~ .warning-tooltip, .has-warning .custom-control-input.is-warning ~ .warning-feedback,
.has-warning .custom-control-input.is-warning ~ .warning-tooltip {
  display: block; }

.was-validated .has-warning .custom-control-input:warning:checked ~ .custom-control-label::before, .has-warning .custom-control-input.is-warning:checked ~ .custom-control-label::before {
  border-color: #ffc233;
  background-color: #ffc233; }

.was-validated .has-warning .custom-control-input:warning:focus ~ .custom-control-label::before, .has-warning .custom-control-input.is-warning:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(255, 179, 0, 0.25); }

.was-validated .has-warning .custom-control-input:warning:focus:not(:checked) ~ .custom-control-label::before, .has-warning .custom-control-input.is-warning:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #FFB300; }

.was-validated .has-warning .custom-file-input:warning ~ .custom-file-label, .has-warning .custom-file-input.is-warning ~ .custom-file-label {
  border-color: #FFB300; }

.was-validated .has-warning .custom-file-input:warning ~ .warning-feedback,
.was-validated .has-warning .custom-file-input:warning ~ .warning-tooltip, .has-warning .custom-file-input.is-warning ~ .warning-feedback,
.has-warning .custom-file-input.is-warning ~ .warning-tooltip {
  display: block; }

.was-validated .has-warning .custom-file-input:warning:focus ~ .custom-file-label, .has-warning .custom-file-input.is-warning:focus ~ .custom-file-label {
  border-color: #FFB300;
  box-shadow: 0 0 0 0.2rem rgba(255, 179, 0, 0.25); }

.has-danger .danger-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #F44336; }

.has-danger .danger-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.71094rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(244, 67, 54, 0.9);
  border-radius: 0.25rem; }

.was-validated .has-danger .form-control:danger, .has-danger .form-control.is-danger {
  border-color: #F44336; }
  .was-validated .has-danger .form-control:danger:focus, .has-danger .form-control.is-danger:focus {
    border-color: #F44336;
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25); }
  .was-validated .has-danger .form-control:danger ~ .danger-feedback,
  .was-validated .has-danger .form-control:danger ~ .danger-tooltip, .has-danger .form-control.is-danger ~ .danger-feedback,
  .has-danger .form-control.is-danger ~ .danger-tooltip {
    display: block; }

.was-validated .has-danger .custom-select:danger, .has-danger .custom-select.is-danger {
  border-color: #F44336; }
  .was-validated .has-danger .custom-select:danger:focus, .has-danger .custom-select.is-danger:focus {
    border-color: #F44336;
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25); }
  .was-validated .has-danger .custom-select:danger ~ .danger-feedback,
  .was-validated .has-danger .custom-select:danger ~ .danger-tooltip, .has-danger .custom-select.is-danger ~ .danger-feedback,
  .has-danger .custom-select.is-danger ~ .danger-tooltip {
    display: block; }

.was-validated .has-danger .form-control-file:danger ~ .danger-feedback,
.was-validated .has-danger .form-control-file:danger ~ .danger-tooltip, .has-danger .form-control-file.is-danger ~ .danger-feedback,
.has-danger .form-control-file.is-danger ~ .danger-tooltip {
  display: block; }

.was-validated .has-danger .form-check-input:danger ~ .form-check-label, .has-danger .form-check-input.is-danger ~ .form-check-label {
  color: #F44336; }

.was-validated .has-danger .form-check-input:danger ~ .danger-feedback,
.was-validated .has-danger .form-check-input:danger ~ .danger-tooltip, .has-danger .form-check-input.is-danger ~ .danger-feedback,
.has-danger .form-check-input.is-danger ~ .danger-tooltip {
  display: block; }

.was-validated .has-danger .custom-control-input:danger ~ .custom-control-label, .has-danger .custom-control-input.is-danger ~ .custom-control-label {
  color: #F44336; }
  .was-validated .has-danger .custom-control-input:danger ~ .custom-control-label::before, .has-danger .custom-control-input.is-danger ~ .custom-control-label::before {
    border-color: #F44336; }

.was-validated .has-danger .custom-control-input:danger ~ .danger-feedback,
.was-validated .has-danger .custom-control-input:danger ~ .danger-tooltip, .has-danger .custom-control-input.is-danger ~ .danger-feedback,
.has-danger .custom-control-input.is-danger ~ .danger-tooltip {
  display: block; }

.was-validated .has-danger .custom-control-input:danger:checked ~ .custom-control-label::before, .has-danger .custom-control-input.is-danger:checked ~ .custom-control-label::before {
  border-color: #f77066;
  background-color: #f77066; }

.was-validated .has-danger .custom-control-input:danger:focus ~ .custom-control-label::before, .has-danger .custom-control-input.is-danger:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25); }

.was-validated .has-danger .custom-control-input:danger:focus:not(:checked) ~ .custom-control-label::before, .has-danger .custom-control-input.is-danger:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #F44336; }

.was-validated .has-danger .custom-file-input:danger ~ .custom-file-label, .has-danger .custom-file-input.is-danger ~ .custom-file-label {
  border-color: #F44336; }

.was-validated .has-danger .custom-file-input:danger ~ .danger-feedback,
.was-validated .has-danger .custom-file-input:danger ~ .danger-tooltip, .has-danger .custom-file-input.is-danger ~ .danger-feedback,
.has-danger .custom-file-input.is-danger ~ .danger-tooltip {
  display: block; }

.was-validated .has-danger .custom-file-input:danger:focus ~ .custom-file-label, .has-danger .custom-file-input.is-danger:focus ~ .custom-file-label {
  border-color: #F44336;
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25); }

.input-sm,
select.input-sm {
  height: 31px; }

legend {
  border-bottom: 1px solid rgba(108, 117, 125, 0.25);
  padding-bottom: 1rem; }

fieldset {
  padding-bottom: 20px;
  border-bottom: 1px dashed rgba(108, 117, 125, 0.25);
  margin-bottom: 20px; }
  fieldset.last-child, fieldset:last-child {
    border-bottom: 0; }
  fieldset .form-group {
    margin-bottom: 0; }

@media only screen and (max-width: 991px) {
  input[type="text"], input[type="email"], input[type="search"], input[type="password"] {
    -webkit-appearance: none; } }

.table {
  font-weight: 400;
  margin-bottom: 2rem; }
  .table > thead > tr > th {
    border-bottom-width: 1px;
    border-color: rgba(108, 117, 125, 0.16);
    position: relative;
    vertical-align: bottom;
    text-overflow: ellipsis;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0;
    height: 48px;
    font-size: 12px;
    padding: 0 18px 0 18px !important;
    padding-bottom: 8px !important; }
    @media only screen and (max-width: 767px) {
      .table > thead > tr > th {
        padding-left: 8px !important;
        padding-right: 8px !important; } }
  .table > tbody > tr > td {
    position: relative;
    vertical-align: middle;
    height: 48px !important;
    padding: 14px 18px;
    border-top: 1px solid rgba(0, 0, 0, 0.045); }
    @media only screen and (max-width: 767px) {
      .table > tbody > tr > td {
        padding-left: 8px !important;
        padding-right: 8px !important; } }
  .table > tbody + tbody {
    border-bottom-width: 1px; }
  .table td, .table th {
    border-top: 0; }

.table-hover > tbody > tr:hover {
  background-color: rgba(108, 117, 125, 0.06); }

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: rgba(108, 117, 125, 0.06); }

.table-bordered {
  border-color: rgba(108, 117, 125, 0.26); }
  .table-bordered > thead > tr > th,
  .table-bordered > thead > tr > td {
    border-bottom-width: 1px; }
  .table-bordered > tbody > tr > td,
  .table-bordered > tbody > tr > th,
  .table-bordered > tfoot > tr > td,
  .table-bordered > tfoot > tr > th,
  .table-bordered > thead > tr > td,
  .table-bordered > thead > tr > th {
    border-color: rgba(108, 117, 125, 0.26); }

.card > .table-bordered, .card > .table-responsive > .table-bordered {
  border: 0; }

.card .table-bordered td:first-child, .card .table-bordered th:first-child {
  border-left: 0; }

.card .table-bordered td:last-child, .card .table-bordered th:last-child {
  border-right: 0; }

.card > .table > tbody:first-child > tr:first-child td,
.card > .table > thead:first-child > tr:first-child th {
  border-top: 0; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: #CFD8DC; }

.table-hover .table-active:hover {
  background-color: #c0ccd1; }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: #c0ccd1; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #4CAF50; }

.table-hover .table-success:hover {
  background-color: #449d48; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #449d48; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #03A9F4; }

.table-hover .table-info:hover {
  background-color: #0398db; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #0398db; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #FFB300; }

.table-hover .table-warning:hover {
  background-color: #e6a100; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #e6a100; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #F44336; }

.table-hover .table-danger:hover {
  background-color: #f32c1e; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f32c1e; }

tr.active, tr.success, tr.info, tr.warning, tr.danger {
  color: rgba(0, 0, 0, 0.67); }
  tr.active > td, tr.success > td, tr.info > td, tr.warning > td, tr.danger > td {
    border-top: 0 !important; }

.progress {
  box-shadow: 0 0 0 #000;
  border-radius: 3px;
  border: 1px solid rgba(240, 240, 240, 0.1);
  background-color: rgba(108, 117, 125, 0.26);
  height: 1.25rem;
  line-height: 1.25rem;
  font-size: inherit; }
  .progress .progress-bar {
    height: 100%;
    box-shadow: 0 0 0 #000;
    line-height: 1.4; }
  .progress.progress-sm {
    height: 16px; }
  .progress.progress-xs {
    height: 8px;
    border-radius: 0;
    border: 0; }

.progress-bar {
  background-color: #dd972d; }

.progress-bar-info {
  background-color: #03A9F4; }

.progress-bar-success {
  background-color: #4CAF50; }

.progress-bar-warning {
  background-color: #FFB300; }

.progress-bar-danger {
  background-color: #F44336; }

.popover {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.014);
  border-color: #e8e8e8;
  border-radius: 3px;
  color: #6d6e70;
  font-size: inherit; }
  .popover .popover-title {
    margin: 0;
    line-height: 1;
    font-size: 1.6rem;
    font-weight: 500; }
  .popover .popover-header {
    margin: 0;
    line-height: 1.2; }

.tooltip {
  font-size: inherit; }

.carousel .carousel-indicators {
  bottom: 0; }

.carousel .carousel-control.left, .carousel .carousel-control.right {
  background-image: none; }

.carousel .carousel-control em {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px; }

.dropdown-menu {
  border: 1px solid #e8e8e8;
  border-radius: 1px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  font-size: inherit; }
  .dropdown-menu .dropdown-item {
    padding: 0 14px;
    line-height: 48px;
    display: block;
    color: #263238; }
    .dropdown-menu .dropdown-item:hover {
      background: #e8e8e8; }
    .dropdown-menu .dropdown-item.active {
      color: #fff;
      background-color: #dd972d; }
  .dropdown-menu .dropdown-item > em {
    margin-right: 10px; }
  .dropdown-menu .dropdown-divider {
    margin: .5625rem 0; }

.dropdown-header {
  margin: 0;
  color: #a1a2a3;
  padding: 0 14px; }

.navbar-default .navbar-nav .show .dropdown-menu > li > a {
  color: #666; }
  .navbar-default .navbar-nav .show .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .show .dropdown-menu > li > a:focus {
    color: #222; }

.navbar,
.navbar .dropdown-menu {
  -webkit-filter: none !important;
          filter: none !important; }

.badge {
  width: 20px;
  height: 20px;
  padding: 0;
  line-height: 20px;
  font-size: 12px;
  border-radius: 50%;
  color: #fff; }

.has-badge {
  position: relative; }
  .has-badge .badge {
    position: absolute;
    top: 18%;
    right: 4px; }
  .has-badge.dropdown-toggle::after {
    display: none; }

/**
 * Animations
 */
.rag-fadeIn-enter {
  opacity: 0.01; }
  .rag-fadeIn-enter-active {
    opacity: 1;
    -webkit-transition: all 300ms ease-out;
    transition: all 300ms ease-out; }

.rag-fadeIn-leave {
  display: none; }

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }
  .animated.infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite; }
  .animated.hinge {
    -webkit-animation-duration: 2s;
    animation-duration: 2s; }

@-webkit-keyframes fadeInRightShort {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(18px, 0, 0);
    transform: translate3d(18px, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInRightShort {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(18px, 0, 0);
    transform: translate3d(18px, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInRightShort {
  -webkit-animation-name: fadeInRightShort;
  animation-name: fadeInRightShort; }

@-webkit-keyframes fadeOutRightShort {
  from {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(18px, 0, 0);
    transform: translate3d(18px, 0, 0); } }

@keyframes fadeOutRightShort {
  from {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(18px, 0, 0);
    transform: translate3d(18px, 0, 0); } }

.fadeOutRightShort {
  -webkit-animation-name: fadeOutRightShort;
  animation-name: fadeOutRightShort; }

@-webkit-keyframes fadeInLeftShort {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-18px, 0, 0);
    transform: translate3d(-18px, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInLeftShort {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-18px, 0, 0);
    transform: translate3d(-18px, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInLeftShort {
  -webkit-animation-name: fadeInLeftShort;
  animation-name: fadeInLeftShort; }

@-webkit-keyframes fadeOutLeftShort {
  from {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-18px, 0, 0);
    transform: translate3d(-18px, 0, 0); } }

@keyframes fadeOutLeftShort {
  from {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(-18px, 0, 0);
    transform: translate3d(-18px, 0, 0); } }

.fadeOutLeftShort {
  -webkit-animation-name: fadeOutLeftShort;
  animation-name: fadeOutLeftShort; }

@-webkit-keyframes fadeOutUpShort {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); } }

@keyframes fadeOutUpShort {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); } }

.fadeOutUpShort {
  -webkit-animation-name: fadeOutUpShort;
  animation-name: fadeOutUpShort; }

@-webkit-keyframes fadeInUpShort {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInUpShort {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInUpShort {
  -webkit-animation-name: fadeInUpShort;
  animation-name: fadeInUpShort; }

@-webkit-keyframes fadeInDownShort {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInDownShort {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInDownShort {
  -webkit-animation-name: fadeInDownShort;
  animation-name: fadeInDownShort; }

@-webkit-keyframes fadeOutDownShort {
  from {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); } }

@keyframes fadeOutDownShort {
  from {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); } }

.fadeOutDownShort {
  -webkit-animation-name: fadeOutDownShort;
  animation-name: fadeOutDownShort; }

@-webkit-keyframes zoomInShort {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.95, 0.95, 0.95);
    transform: scale3d(0.95, 0.95, 0.95); }
  50% {
    opacity: 1; } }

@keyframes zoomInShort {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.95, 0.95, 0.95);
    transform: scale3d(0.95, 0.95, 0.95); }
  50% {
    opacity: 1; } }

.zoomInShort {
  -webkit-animation-name: zoomInShort;
  animation-name: zoomInShort; }

@-webkit-keyframes zoomBack {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.5, 0.5, 0.5);
    transform: scale3d(0.5, 0.5, 0.5); }
  100% {
    opacity: 0; } }

@keyframes zoomBack {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.5, 0.5, 0.5);
    transform: scale3d(0.5, 0.5, 0.5); }
  100% {
    opacity: 0; } }

.zoomBack {
  -webkit-animation-name: zoomBack;
  animation-name: zoomBack; }

/**
 * Buttons Extras
 */
.input-group-btn > .btn {
  border-color: rgba(0, 0, 0, 0.26); }

.btn-inverse {
  color: #212529;
  background-color: #fff;
  border-color: #37474F; }
  .btn-inverse:hover {
    color: #fff;
    background-color: #37474F;
    border-color: #222c31; }
  .btn-inverse:focus, .btn-inverse.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 66, 73, 0.5); }
  .btn-inverse.disabled, .btn-inverse:disabled {
    color: #212529;
    background-color: #fff;
    border-color: #37474F; }
  .btn-inverse:not(:disabled):not(.disabled):active, .btn-inverse:not(:disabled):not(.disabled).active,
  .show > .btn-inverse.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #1d2529; }
    .btn-inverse:not(:disabled):not(.disabled):active:focus, .btn-inverse:not(:disabled):not(.disabled).active:focus,
    .show > .btn-inverse.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 66, 73, 0.5); }

.btn-flat {
  border-color: transparent !important;
  border-radius: 2px;
  background-color: transparent;
  text-transform: uppercase; }
  .btn-flat:active, .btn-flat.active {
    box-shadow: 0 0 0 #000; }
  .btn-flat.btn-primary {
    color: #dd972d; }
    .btn-flat.btn-primary:hover, .btn-flat.btn-primary:focus, .btn-flat.btn-primary:active {
      color: #fff; }
    .btn-flat.btn-primary:focus {
      background-color: #dd972d; }
    .btn-flat.btn-primary[disabled], .btn-flat.btn-primary.disabled {
      color: rgba(221, 151, 45, 0.75) !important; }
  .btn-flat.btn-success {
    color: #4CAF50; }
    .btn-flat.btn-success:hover, .btn-flat.btn-success:focus, .btn-flat.btn-success:active {
      color: #fff; }
    .btn-flat.btn-success:focus {
      background-color: #4CAF50; }
    .btn-flat.btn-success[disabled], .btn-flat.btn-success.disabled {
      color: rgba(76, 175, 80, 0.75) !important; }
  .btn-flat.btn-danger {
    color: #F44336; }
    .btn-flat.btn-danger:hover, .btn-flat.btn-danger:focus, .btn-flat.btn-danger:active {
      color: #fff; }
    .btn-flat.btn-danger:focus {
      background-color: #F44336; }
    .btn-flat.btn-danger[disabled], .btn-flat.btn-danger.disabled {
      color: rgba(244, 67, 54, 0.75) !important; }
  .btn-flat.btn-warning {
    color: #FFB300; }
    .btn-flat.btn-warning:hover, .btn-flat.btn-warning:focus, .btn-flat.btn-warning:active {
      color: #fff; }
    .btn-flat.btn-warning:focus {
      background-color: #FFB300; }
    .btn-flat.btn-warning[disabled], .btn-flat.btn-warning.disabled {
      color: rgba(255, 179, 0, 0.75) !important; }
  .btn-flat.btn-info {
    color: #03A9F4; }
    .btn-flat.btn-info:hover, .btn-flat.btn-info:focus, .btn-flat.btn-info:active {
      color: #fff; }
    .btn-flat.btn-info:focus {
      background-color: #03A9F4; }
    .btn-flat.btn-info[disabled], .btn-flat.btn-info.disabled {
      color: rgba(3, 169, 244, 0.75) !important; }
  .btn-flat.btn-inverse {
    color: #37474F; }
    .btn-flat.btn-inverse:hover, .btn-flat.btn-inverse:focus, .btn-flat.btn-inverse:active {
      color: #fff; }
    .btn-flat.btn-inverse:focus {
      background-color: #37474F; }
    .btn-flat.btn-inverse[disabled], .btn-flat.btn-inverse.disabled {
      color: rgba(55, 71, 79, 0.75) !important; }
  .btn-flat.text-white {
    color: #fff !important; }
  .btn-flat[disabled], .btn-flat.disabled {
    background-color: transparent !important; }
  .btn-flat.btn-flat-icon {
    border-radius: 50%;
    font-size: 24px;
    height: 32px;
    width: 32px;
    padding: 0;
    overflow: hidden;
    color: inherit !important; }
    .btn-flat.btn-flat-icon > em {
      line-height: 32px; }
    .btn-flat.btn-flat-icon:hover, .btn-flat.btn-flat-icon:focus, .btn-flat.btn-flat-icon:active {
      background-color: rgba(158, 158, 158, 0.2) !important; }

.btn-raised {
  border: 0;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.014); }
  .btn-raised:hover, .btn-raised:active, .btn-raised.active {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4) !important; }

.float-right > .btn-flat-icon {
  margin-top: -4px; }

.btn-circle {
  width: 46px;
  height: 46px;
  overflow: hidden;
  border-radius: 50% !important;
  line-height: 46px;
  padding: 0;
  text-align: center; }

.btn-outline {
  background-color: transparent;
  border-color: #fff; }
  .btn-outline:hover, .btn-outline:focus {
    background-color: #fff;
    color: #dd972d; }

.btn-xl {
  padding: 20px 16px;
  font-size: 18px; }

.btn-square {
  border-radius: 0; }

.btn-pill-left, .btn-oval {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding-left: 18px; }

.btn-pill-right, .btn-oval {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  padding-right: 18px; }

.btn-labeled {
  padding-top: 0;
  padding-bottom: 0; }

.btn-label {
  position: relative;
  background: transparent;
  display: inline-block;
  padding: 6px 16px;
  left: -16px;
  border-radius: 3px 0 0 3px; }
  .btn-label.btn-label-right {
    left: auto;
    right: -16px;
    border-radius: 0 3px 3px 0; }
  .btn-label:after {
    content: "";
    position: absolute;
    top: 5px;
    bottom: 5px;
    right: 0;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.1); }
  .btn-label.btn-label-right {
    text-indent: -2px; }
    .btn-label.btn-label-right:after {
      left: 0;
      right: auto; }

.btn-lg .btn-label {
  padding: 10px 20px;
  left: -20px;
  border-radius: 5px 0 0 5px; }
  .btn-lg .btn-label.btn-label-right {
    left: auto;
    right: -20px;
    border-radius: 0 5px 5px 0; }

.btn-sm .btn-label {
  padding: 1px 5px;
  left: -5px;
  border-radius: 2px 0 0 2px; }
  .btn-sm .btn-label.btn-label-right {
    left: auto;
    right: -5px;
    border-radius: 0 2px 2px 0; }

.btn-fw {
  min-width: 80px; }
  .btn-fw.btn-sm {
    min-width: 40px; }
  .btn-fw.btn-md {
    min-width: 60px; }
  .btn-fw.btn-lg {
    min-width: 140px; }

.pagination-rounded .page-item > .page-link {
  border-radius: 50% !important;
  margin-right: 4px;
  margin-left: 4px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  padding: 0;
  text-align: center; }
  .pagination-rounded .page-item > .page-link > span {
    position: relative;
    top: -1px; }

.pagination-rounded.pagination-lg .page-item > .page-link {
  width: 44px;
  height: 44px;
  line-height: 44px; }

.float-left.btn-group,
.float-right.btn-group {
  position: relative;
  z-index: 1; }

/**
 * Dropdowns Extras
 */
.dropdown-lg > .dropdown-menu {
  min-width: 200px; }

.dropdown-list > .dropdown-menu {
  padding: 0;
  min-width: 220px; }

.dropdown-list .list-group {
  margin: 0; }

.dropdown-list .list-group-item {
  border-radius: 0;
  border-left: 0;
  border-right: 0; }
  .dropdown-list .list-group-item:first-child {
    border-top: 0; }
  .dropdown-list .list-group-item:last-child {
    border-bottom: 0; }

.dropdown > a {
  position: relative; }
  .dropdown > a > .label, .dropdown > a > .tag {
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px 5px; }
    @media only screen and (min-width: 768px) {
      .dropdown > a > .label, .dropdown > a > .tag {
        top: 10px; } }

.dropdown-menu-header {
  padding: 10px 15px;
  background-color: #fafafa;
  border-bottom: 1px solid #ddd; }

/**
 * Rows - Extras
 */
.row {
  margin-right: -4px;
  margin-left: -4px; }
  .row .col-lg-1, .row .col-lg-2, .row .col-lg-3, .row .col-lg-4, .row .col-lg-5, .row .col-lg-6, .row .col-lg-7, .row .col-lg-8, .row .col-lg-9, .row .col-lg-10, .row .col-lg-11, .row .col-lg-12,
  .row .col-xl-1, .row .col-xl-2, .row .col-xl-3, .row .col-xl-4, .row .col-xl-5, .row .col-xl-6, .row .col-xl-7, .row .col-xl-8, .row .col-xl-9, .row .col-xl-10, .row .col-xl-11, .row .col-xl-12,
  .row .col-md-1, .row .col-md-2, .row .col-md-3, .row .col-md-4, .row .col-md-5, .row .col-md-6, .row .col-md-7, .row .col-md-8, .row .col-md-9, .row .col-md-10, .row .col-md-11, .row .col-md-12,
  .row .col-sm-1, .row .col-sm-2, .row .col-sm-3, .row .col-sm-4, .row .col-sm-5, .row .col-sm-6, .row .col-sm-7, .row .col-sm-8, .row .col-sm-9, .row .col-sm-10, .row .col-sm-11, .row .col-sm-12,
  .row .col-xs-1, .row .col-xs-2, .row .col-xs-3, .row .col-xs-4, .row .col-xs-5, .row .col-xs-6, .row .col-xs-7, .row .col-xs-8, .row .col-xs-9, .row .col-xs-10, .row .col-xs-11, .row .col-xs-12,
  .row .col-1, .row .col-2, .row .col-3, .row .col-4, .row .col-5, .row .col-6, .row .col-7, .row .col-8, .row .col-9, .row .col-10, .row .col-11, .row .col-12 {
    padding-right: 4px;
    padding-left: 4px; }
  @media only screen and (min-width: 480px) {
    .row {
      margin-right: -8px;
      margin-left: -8px; }
      .row .col-lg-1, .row .col-lg-2, .row .col-lg-3, .row .col-lg-4, .row .col-lg-5, .row .col-lg-6, .row .col-lg-7, .row .col-lg-8, .row .col-lg-9, .row .col-lg-10, .row .col-lg-11, .row .col-lg-12,
      .row .col-xl-1, .row .col-xl-2, .row .col-xl-3, .row .col-xl-4, .row .col-xl-5, .row .col-xl-6, .row .col-xl-7, .row .col-xl-8, .row .col-xl-9, .row .col-xl-10, .row .col-xl-11, .row .col-xl-12,
      .row .col-md-1, .row .col-md-2, .row .col-md-3, .row .col-md-4, .row .col-md-5, .row .col-md-6, .row .col-md-7, .row .col-md-8, .row .col-md-9, .row .col-md-10, .row .col-md-11, .row .col-md-12,
      .row .col-sm-1, .row .col-sm-2, .row .col-sm-3, .row .col-sm-4, .row .col-sm-5, .row .col-sm-6, .row .col-sm-7, .row .col-sm-8, .row .col-sm-9, .row .col-sm-10, .row .col-sm-11, .row .col-sm-12,
      .row .col-xs-1, .row .col-xs-2, .row .col-xs-3, .row .col-xs-4, .row .col-xs-5, .row .col-xs-6, .row .col-xs-7, .row .col-xs-8, .row .col-xs-9, .row .col-xs-10, .row .col-xs-11, .row .col-xs-12,
      .row .col-1, .row .col-2, .row .col-3, .row .col-4, .row .col-5, .row .col-6, .row .col-7, .row .col-8, .row .col-9, .row .col-10, .row .col-11, .row .col-12 {
        padding-right: 8px;
        padding-left: 8px; } }
  @media only screen and (min-width: 992px) {
    .row {
      margin-right: -12px;
      margin-left: -12px; }
      .row .col-lg-1, .row .col-lg-2, .row .col-lg-3, .row .col-lg-4, .row .col-lg-5, .row .col-lg-6, .row .col-lg-7, .row .col-lg-8, .row .col-lg-9, .row .col-lg-10, .row .col-lg-11, .row .col-lg-12,
      .row .col-xl-1, .row .col-xl-2, .row .col-xl-3, .row .col-xl-4, .row .col-xl-5, .row .col-xl-6, .row .col-xl-7, .row .col-xl-8, .row .col-xl-9, .row .col-xl-10, .row .col-xl-11, .row .col-xl-12,
      .row .col-md-1, .row .col-md-2, .row .col-md-3, .row .col-md-4, .row .col-md-5, .row .col-md-6, .row .col-md-7, .row .col-md-8, .row .col-md-9, .row .col-md-10, .row .col-md-11, .row .col-md-12,
      .row .col-sm-1, .row .col-sm-2, .row .col-sm-3, .row .col-sm-4, .row .col-sm-5, .row .col-sm-6, .row .col-sm-7, .row .col-sm-8, .row .col-sm-9, .row .col-sm-10, .row .col-sm-11, .row .col-sm-12,
      .row .col-xs-1, .row .col-xs-2, .row .col-xs-3, .row .col-xs-4, .row .col-xs-5, .row .col-xs-6, .row .col-xs-7, .row .col-xs-8, .row .col-xs-9, .row .col-xs-10, .row .col-xs-11, .row .col-xs-12,
      .row .col-1, .row .col-2, .row .col-3, .row .col-4, .row .col-5, .row .col-6, .row .col-7, .row .col-8, .row .col-9, .row .col-10, .row .col-11, .row .col-12 {
        padding-right: 12px;
        padding-left: 12px; } }

.row-table {
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
  margin: 0; }
  .row-table > [class*="col-"] {
    display: table-cell;
    float: none;
    table-layout: fixed;
    vertical-align: middle; }

.row-flush > [class*="col-"] {
  padding-left: 0;
  padding-right: 0; }

.t-grid .t-row {
  width: 100%;
  display: table;
  table-layout: fixed; }

.t-grid .t-col, .t-grid .t-cell {
  display: table-cell; }

/**
 * Modals
 */
.modal-header,
.modal-footer {
  border-color: rgba(108, 117, 125, 0.12); }

.modal.modal-right .modal-dialog,
.modal.modal-left .modal-dialog,
.modal.modal-bottom .modal-dialog,
.modal.modal-top .modal-dialog {
  position: absolute;
  margin: 0;
  max-width: none;
  transition-transform: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .modal.modal-right .modal-dialog > .modal-content,
  .modal.modal-left .modal-dialog > .modal-content,
  .modal.modal-bottom .modal-dialog > .modal-content,
  .modal.modal-top .modal-dialog > .modal-content {
    border-radius: 0;
    border: 0; }

.modal.modal-right.fade.show .modal-dialog,
.modal.modal-left.fade.show .modal-dialog,
.modal.modal-bottom.fade.show .modal-dialog,
.modal.modal-top.fade.show .modal-dialog {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0); }

.modal.fade.modal-right .modal-dialog,
.modal.fade.modal-left .modal-dialog,
.modal.fade.modal-bottom .modal-dialog,
.modal.fade.modal-top .modal-dialog {
  transition-transform: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }

.modal.modal-right .modal-dialog {
  right: 0;
  bottom: 0;
  top: 0;
  width: 240px; }
  .modal.modal-right .modal-dialog > .modal-content {
    min-height: 100%; }

.modal.modal-right.modal-auto-size .modal-dialog {
  width: 80%; }
  @media only screen and (min-width: 768px) {
    .modal.modal-right.modal-auto-size .modal-dialog {
      width: 50%; } }

.modal.modal-right.fade .modal-dialog {
  -webkit-transform: translate(100%, 0);
          transform: translate(100%, 0); }

.modal.modal-left .modal-dialog {
  left: 0;
  bottom: 0;
  top: 0;
  width: 240px; }
  .modal.modal-left .modal-dialog > .modal-content {
    min-height: 100%; }

.modal.modal-left.modal-auto-size .modal-dialog {
  width: 80%; }
  @media only screen and (min-width: 768px) {
    .modal.modal-left.modal-auto-size .modal-dialog {
      width: 50%; } }

.modal.modal-left.fade .modal-dialog {
  -webkit-transform: translate(-100%, 0);
          transform: translate(-100%, 0); }

.modal.modal-top .modal-dialog {
  right: 0;
  left: 0;
  top: 0;
  width: 100%; }
  .modal.modal-top .modal-dialog > .modal-content {
    width: 100%; }

.modal.modal-top.fade .modal-dialog {
  -webkit-transform: translate(0, -100%);
          transform: translate(0, -100%); }

.modal.modal-bottom .modal-dialog {
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%; }
  .modal.modal-bottom .modal-dialog > .modal-content {
    width: 100%; }

.modal.modal-bottom.fade .modal-dialog {
  -webkit-transform: translate(0, 100%);
          transform: translate(0, 100%); }

.modal-content {
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.14), 0 0 2px 2px rgba(0, 0, 0, 0.098), 0 0 5px 1px rgba(0, 0, 0, 0.084); }

.modal-backdrop {
  opacity: 0;
  will-change: opacity; }
  .modal-backdrop.show {
    -webkit-transition: opacity .28s ease-in-out;
    transition: opacity .28s ease-in-out; }

.modal-backdrop-light .modal-backdrop {
  background-color: #fff; }
  .modal-backdrop-light .modal-backdrop.show {
    opacity: .9; }

.modal-backdrop-soft .modal-backdrop.show {
  opacity: .3; }

.modal-backdrop {
  position: fixed;
  bottom: 0; }

/**
 * Themes
 */
.theme-1 .layout-container > aside {
  background-color: #000;
  color: #6d6e70; }
  .theme-1 .layout-container > aside > .sidebar-header {
    background-color: #e8e8e8;
    color: #000; }

.theme-1 .layout-container > header {
  background-color: #e8e8e8;
  color: #000; }

.theme-2 .layout-container > aside {
  background-color: #263238;
  color: #fff; }
  .theme-2 .layout-container > aside > .sidebar-header {
    background-color: #263238;
    color: #fff; }

.theme-2 .layout-container > header {
  background-color: #1976D2;
  color: #fff; }

.theme-2 .layout-container > main {
  background-color: #ECEFF1; }

.theme-3 {
  background-color: #2f3c43; }
  .theme-3 .layout-container > aside {
    background-color: #263238;
    color: #fff; }
    .theme-3 .layout-container > aside > .sidebar-header {
      background-color: #263238;
      color: #fff; }
  .theme-3 .layout-container > header {
    background-color: #37474F;
    color: #fff; }
  .theme-3 .layout-container > main {
    background-color: #2f3c43;
    color: #f1f1f1; }
    .theme-3 .layout-container > main .bg-white:not([class*='bg-']),
    .theme-3 .layout-container > main .card:not([class*='bg-']),
    .theme-3 .layout-container > main .cardbox:not([class*='bg-']),
    .theme-3 .layout-container > main .card-default > .card-header:not([class*='bg-']) {
      background-color: #35444c;
      color: inherit; }
    .theme-3 .layout-container > main .bg-white {
      background-color: #35444c !important;
      color: inherit; }
    .theme-3 .layout-container > main .well, .theme-3 .layout-container > main .jumbotron, .theme-3 .layout-container > main .breadcrumb, .theme-3 .layout-container > main .modal-content {
      background-color: #35444c;
      color: inherit; }
    .theme-3 .layout-container > main .btn-secondary {
      color: #212529;
      background-color: #fff;
      border-color: #37474F; }
      .theme-3 .layout-container > main .btn-secondary:hover {
        color: #fff;
        background-color: #2d3940;
        border-color: #222c31; }
      .theme-3 .layout-container > main .btn-secondary:focus, .theme-3 .layout-container > main .btn-secondary.focus {
        box-shadow: 0 0 0 0.2rem rgba(52, 66, 73, 0.5); }
      .theme-3 .layout-container > main .btn-secondary.disabled, .theme-3 .layout-container > main .btn-secondary:disabled {
        color: #212529;
        background-color: #fff;
        border-color: #37474F; }
      .theme-3 .layout-container > main .btn-secondary:not(:disabled):not(.disabled):active, .theme-3 .layout-container > main .btn-secondary:not(:disabled):not(.disabled).active,
      .show > .theme-3 .layout-container > main .btn-secondary.dropdown-toggle {
        color: #212529;
        background-color: #e6e6e6;
        border-color: #1d2529; }
        .theme-3 .layout-container > main .btn-secondary:not(:disabled):not(.disabled):active:focus, .theme-3 .layout-container > main .btn-secondary:not(:disabled):not(.disabled).active:focus,
        .show > .theme-3 .layout-container > main .btn-secondary.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.2rem rgba(52, 66, 73, 0.5); }
    .theme-3 .layout-container > main .show > .dropdown-toggle.btn-secondary, .theme-3 .layout-container > main .show > .dropdown-toggle.btn-secondary:hover, .theme-3 .layout-container > main .show > .dropdown-toggle.btn-secondary:active, .theme-3 .layout-container > main .show > .dropdown-toggle.btn-secondary.active, .theme-3 .layout-container > main .show > .dropdown-toggle.btn-secondary:focus {
      color: #fff;
      background-color: #37474F;
      border-color: #2d3940; }
    .theme-3 .layout-container > main .form-control {
      color: inherit;
      background-color: rgba(240, 240, 240, 0.1);
      border-color: rgba(240, 240, 240, 0.12); }
      .theme-3 .layout-container > main .form-control:focus {
        border-color: rgba(240, 240, 240, 0.36); }
    .theme-3 .layout-container > main .form-control[disabled],
    .theme-3 .layout-container > main .form-control[readonly],
    .theme-3 .layout-container > main fieldset[disabled] .form-control {
      background-color: rgba(240, 240, 240, 0.1); }
    .theme-3 .layout-container > main select:not([multiple]) option {
      color: #6d6e70; }
    .theme-3 .layout-container > main .input-group-addon,
    .theme-3 .layout-container > main .input-group-button {
      background-color: transparent;
      border: 1px solid rgba(108, 117, 125, 0.26);
      color: inherit; }
    .theme-3 .layout-container > main .nav-tabs > .nav-item > .nav-link.active {
      color: #fff; }
    .theme-3 .layout-container > main .note-editor .note-editing-area .note-editable {
      background-color: rgba(240, 240, 240, 0.1); }
    .theme-3 .layout-container > main .photo {
      background-color: #37474F; }
  .theme-3 .sidebar-header-logo > svg, .theme-3 .sidebar-header-logo > em,
  .theme-3 .nav-icon > svg,
  .theme-3 .nav-icon > em {
    color: #dd972d !important;
    fill: #4CAF50 !important; }

.theme-4 .layout-container > aside {
  background-color: #263238;
  color: #fff; }
  .theme-4 .layout-container > aside > .sidebar-header {
    background-color: #263238;
    color: #fff; }

.theme-4 .layout-container > header {
  background-color: #4CAF50;
  color: #fff; }

.theme-4 .layout-container > main {
  background-color: #e8e8e8; }

.theme-4 .sidebar-header-logo > svg, .theme-4 .sidebar-header-logo > em,
.theme-4 .nav-icon > svg,
.theme-4 .nav-icon > em {
  color: #4CAF50 !important;
  fill: #a3d7a5 !important; }

.theme-5 .layout-container > aside {
  background-color: #263238;
  color: #fff; }
  .theme-5 .layout-container > aside > .sidebar-header {
    background-color: #263238;
    color: #fff; }

.theme-5 .layout-container > header {
  background-color: #03A9F4;
  color: #fff; }

.theme-5 .layout-container > main {
  background-color: #e8e8e8; }

.theme-5 .sidebar-header-logo > svg, .theme-5 .sidebar-header-logo > em,
.theme-5 .nav-icon > svg,
.theme-5 .nav-icon > em {
  color: #03A9F4 !important;
  fill: #79d4fd !important; }

.theme-6 .layout-container > aside {
  background-color: #263238;
  color: #fff; }
  .theme-6 .layout-container > aside > .sidebar-header {
    background-color: #263238;
    color: #fff; }

.theme-6 .layout-container > header {
  background-color: #9575CD;
  color: #fff; }

.theme-6 .layout-container > main {
  background-color: #e8e8e8; }

.theme-6 .sidebar-header-logo > svg, .theme-6 .sidebar-header-logo > em,
.theme-6 .nav-icon > svg,
.theme-6 .nav-icon > em {
  color: #9575CD !important;
  fill: #ddd3ef !important; }

.theme-7 .layout-container > aside {
  background-color: #fff;
  color: #6d6e70; }
  .theme-7 .layout-container > aside > .sidebar-header {
    background-color: #455A64;
    color: #fff; }

.theme-7 .layout-container > header {
  background-color: #455A64;
  color: #fff; }

.theme-7 .layout-container > main {
  background-color: #ECEFF1; }

.theme-8 .layout-container > aside {
  background-color: #263238;
  color: #fff; }
  .theme-8 .layout-container > aside > .sidebar-header {
    background-color: #263238;
    color: #fff; }

.theme-8 .layout-container > header {
  background-color: #fff;
  color: #6d6e70; }

.theme-8 .layout-container > main {
  background-color: #ECEFF1; }

.theme-8 .sidebar-header-logo > svg, .theme-8 .sidebar-header-logo > em,
.theme-8 .nav-icon > svg,
.theme-8 .nav-icon > em {
  color: #dd972d !important;
  fill: #f1f1f1 !important; }

.theme-9 .layout-container > aside {
  background-color: #fff;
  color: #6d6e70; }
  .theme-9 .layout-container > aside > .sidebar-header {
    background-color: #fff;
    color: #6d6e70; }

.theme-9 .layout-container > header {
  background-color: #fff;
  color: #6d6e70; }

.theme-9 .layout-container > main {
  background-color: #ECEFF1; }

.theme-9 .sidebar-header-logo > svg, .theme-9 .sidebar-header-logo > em,
.theme-9 .nav-icon > svg,
.theme-9 .nav-icon > em {
  color: #738c98 !important;
  fill: #90A4AE !important; }

.preview-theme-1 .preview-header {
  background-color: #1976D2; }

.preview-theme-1 .preview-sidebar {
  background-color: #fff; }

.preview-theme-1 .preview-content {
  background-color: #f1f2f3; }

.preview-theme-2 .preview-header {
  background-color: #1976D2; }

.preview-theme-2 .preview-sidebar {
  background-color: #263238; }

.preview-theme-2 .preview-content {
  background-color: #ECEFF1; }

.preview-theme-3 .preview-header {
  background-color: #37474F; }

.preview-theme-3 .preview-sidebar {
  background-color: #263238; }

.preview-theme-3 .preview-content {
  background-color: #37474F; }

.preview-theme-4 .preview-header {
  background-color: #4CAF50; }

.preview-theme-4 .preview-sidebar {
  background-color: #fff; }

.preview-theme-4 .preview-content {
  background-color: #e8e8e8; }

.preview-theme-5 .preview-header {
  background-color: #03A9F4; }

.preview-theme-5 .preview-sidebar {
  background-color: #fff; }

.preview-theme-5 .preview-content {
  background-color: #e8e8e8; }

.preview-theme-6 .preview-header {
  background-color: #9575CD; }

.preview-theme-6 .preview-sidebar {
  background-color: #fff; }

.preview-theme-6 .preview-content {
  background-color: #e8e8e8; }

.preview-theme-7 .preview-header {
  background-color: #455A64; }

.preview-theme-7 .preview-sidebar {
  background-color: #fff; }

.preview-theme-7 .preview-content {
  background-color: #ECEFF1; }

.preview-theme-8 .preview-header {
  background-color: #fff; }

.preview-theme-8 .preview-sidebar {
  background-color: #263238; }

.preview-theme-8 .preview-content {
  background-color: #ECEFF1; }

.preview-theme-9 .preview-header {
  background-color: #fff; }

.preview-theme-9 .preview-sidebar {
  background-color: #fff; }

.preview-theme-9 .preview-content {
  background-color: #ECEFF1; }

/**
 * Typography
 */
h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
  opacity: 0.54;
  font-size: 0.6em;
  color: inherit; }

h1 > small, h2 > small, h3 > small, h4 > small, h5 > small, h6 > small {
  margin-left: 5px; }

small {
  color: inherit; }

h1 {
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
  margin-top: 24px;
  margin-bottom: 24px; }

h2 {
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
  margin-top: 24px;
  margin-bottom: 24px; }

h3 {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  margin-top: 24px;
  margin-bottom: 24px; }

h4 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 24px;
  margin-bottom: 16px; }

h5 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
  margin-top: 24px;
  margin-bottom: 16px; }

h6 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04em;
  margin-top: 24px;
  margin-bottom: 16px; }

.blockquote {
  padding: 1rem 2rem;
  margin: 0 0 2rem;
  font-size: 1.75rem;
  border-left: .5rem solid #eee; }
  .blockquote p:last-child {
    margin: 0; }
  .blockquote .small, .blockquote footer, .blockquote small {
    display: block;
    font-size: 80%;
    line-height: 1.42857143;
    color: #777; }
    .blockquote .small:before, .blockquote footer:before, .blockquote small:before {
      content: '\2014   \A0'; }

/**
 * Custom form elements
 *    - Checkbox
 *    - Radios
 */
.c-checkbox,
.c-radio {
  margin-right: 4px; }
  .c-checkbox input,
  .c-radio input {
    opacity: 0;
    position: absolute;
    margin-left: 0 !important; }
  .c-checkbox span,
  .c-radio span {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    border: 1px solid rgba(108, 117, 125, 0.36);
    margin-right: 5px;
    text-align: center; }
    .c-checkbox span:before,
    .c-radio span:before {
      margin-left: 0; }
  .c-checkbox:hover span,
  .c-radio:hover span {
    border-color: #dd972d; }

.c-radio span,
.c-checkbox-rounded span {
  border-radius: 500px; }

.c-checkbox span:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  text-align: center !important;
  font-size: 12px;
  line-height: 18px;
  vertical-align: middle; }

.c-checkbox input[type=checkbox]:checked + span:before {
  color: #fff;
  opacity: 1;
  -webkit-transition: color .3s ease-out;
  transition: color .3s ease-out; }

.c-checkbox input[type=checkbox]:checked + span {
  border-color: #dd972d;
  background-color: #dd972d; }

.c-checkbox input[type=checkbox]:disabled + span {
  border-color: rgba(108, 117, 125, 0.12) !important;
  background-color: rgba(108, 117, 125, 0.12) !important;
  cursor: not-allowed; }

.c-radio span:before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  top: 50%;
  left: 50%;
  margin-top: -5px;
  margin-left: -5px;
  border-radius: 500px;
  opacity: 0; }

.c-radio input[type=radio]:checked + span:before {
  color: #fff;
  opacity: 1;
  -webkit-transition: color .3s ease-out;
  transition: color .3s ease-out; }

.c-radio input[type=radio]:checked + span {
  border-color: #dd972d;
  background-color: rgba(108, 117, 125, 0.12); }
  .c-radio input[type=radio]:checked + span:before {
    background-color: #dd972d; }

.c-radio input[type=radio]:disabled + span {
  border-color: rgba(108, 117, 125, 0.12) !important;
  cursor: not-allowed; }
  .c-radio input[type=radio]:disabled + span:before {
    background-color: rgba(108, 117, 125, 0.12); }

/**
 * Forms - Note Editor
 */
.note-area > textarea {
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0.1em, rgba(108, 117, 125, 0.26)), color-stop(0.1em, transparent));
  background-image: linear-gradient(rgba(108, 117, 125, 0.26) 0.1em, transparent 0.1em);
  background-size: 100% 20px;
  background-color: transparent !important;
  line-height: 20px;
  margin-top: 5px;
  padding: 0;
  padding-bottom: 1px;
  border: none !important; }

.note-area.note-area-margin {
  position: relative; }
  .note-area.note-area-margin > textarea {
    padding-left: 55px; }
  .note-area.note-area-margin:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 45px;
    width: 5px;
    background-color: rgba(255, 179, 0, 0.5); }

/**
 * Forms - Switch
 */
.switch .form-control {
  padding-top: 7px;
  margin-bottom: 0; }

.switch * {
  cursor: pointer; }

.switch input {
  opacity: 0;
  position: absolute;
  z-index: -1; }

.switch {
  margin-right: 8px; }
  .switch span {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 15px;
    background-color: #9e9e9e;
    border: 0;
    border-radius: 8px;
    vertical-align: middle;
    -webkit-transition: all 0.2s cubic-bezier(0.35, 0, 0.25, 1) 0.1s;
    transition: all 0.2s cubic-bezier(0.35, 0, 0.25, 1) 0.1s; }
  .switch span:after {
    content: "";
    position: absolute;
    background-color: #fff;
    top: -2px;
    left: 0;
    height: 20px;
    width: 20px;
    border: 0;
    border-radius: 400px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.084);
    -webkit-transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1); }
  .switch.switch-primary input:checked + span {
    background-color: #dd972d; }
    .switch.switch-primary input:checked + span:after {
      background-color: #dd972d; }
  .switch.switch-primary.switch-warn input:checked + span {
    background-color: rgba(221, 151, 45, 0.5); }
  .switch.switch-info input:checked + span {
    background-color: #03A9F4; }
    .switch.switch-info input:checked + span:after {
      background-color: #03A9F4; }
  .switch.switch-info.switch-warn input:checked + span {
    background-color: rgba(3, 169, 244, 0.5); }
  .switch.switch-danger input:checked + span {
    background-color: #F44336; }
    .switch.switch-danger input:checked + span:after {
      background-color: #F44336; }
  .switch.switch-danger.switch-warn input:checked + span {
    background-color: rgba(244, 67, 54, 0.5); }
  .switch.switch-warning input:checked + span {
    background-color: #FFB300; }
    .switch.switch-warning input:checked + span:after {
      background-color: #FFB300; }
  .switch.switch-warning.switch-warn input:checked + span {
    background-color: rgba(255, 179, 0, 0.5); }
  .switch.switch-success input:checked + span {
    background-color: #4CAF50; }
    .switch.switch-success input:checked + span:after {
      background-color: #4CAF50; }
  .switch.switch-success.switch-warn input:checked + span {
    background-color: rgba(76, 175, 80, 0.5); }
  .switch.switch-purple input:checked + span {
    background-color: #7E57C2; }
    .switch.switch-purple input:checked + span:after {
      background-color: #7E57C2; }
  .switch.switch-purple.switch-warn input:checked + span {
    background-color: rgba(126, 87, 194, 0.5); }

.switch input:checked + span {
  background-color: #EC407A; }
  .switch input:checked + span:after {
    background-color: #EC407A; }

.switch.switch-warn input:checked + span {
  background-color: rgba(236, 64, 122, 0.5); }

.switch input:checked + span:after {
  left: 50%; }

.switch input:disabled + span {
  background-color: rgba(158, 158, 158, 0.5) !important; }
  .switch input:disabled + span:after {
    background-color: #fff !important; }

/**
 * React Datepicker
 */
.ui-datepicker .react-datepicker {
  width: 100%;
  border: 0; }
  .ui-datepicker .react-datepicker .react-datepicker__month-container {
    float: none; }

.ui-datepicker .react-datepicker__header {
  background-color: #fff;
  border: 0;
  padding: 0; }

.ui-datepicker .react-datepicker__day-names, .ui-datepicker .react-datepicker__week {
  display: flex;
  justify-content: space-around; }

.ui-datepicker .react-datepicker__day-name, .ui-datepicker .react-datepicker__day, .ui-datepicker .react-datepicker__time-name {
  padding: 0 12px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin: 0;
  width: 2.4rem; }

.ui-datepicker .react-datepicker__day-name {
  font-weight: bold; }

.ui-datepicker .react-datepicker__current-month {
  padding: 16px 12px;
  font-size: 12px; }

.ui-datepicker .react-datepicker__navigation {
  top: 25px; }

.ui-datepicker .react-datepicker__day--today {
  border-radius: 0 !important;
  background-image: none;
  background-color: #E91E63 !important;
  color: #fff !important; }

@charset "UTF-8";
/**
 * Cardbox
 */
.cardbox {
  position: relative;
  border-radius: 3px;
  background-color: #fff;
  color: #4F5256;
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 8px; }
  @media only screen and (min-width: 480px) {
    .cardbox {
      margin-bottom: 12px; } }
  @media only screen and (min-width: 992px) {
    .cardbox {
      margin-bottom: 24px; } }
  .cardbox .cardbox-heading {
    padding: 16px;
    margin: 0; }
    .cardbox .cardbox-heading > .cardbox-title {
      margin: 0;
      font-size: 18px; }
    .cardbox .cardbox-heading > .cardbox-icon {
      float: right;
      color: rgba(255, 255, 255, 0.87);
      font-size: 20px; }
    .cardbox .cardbox-heading > small {
      color: rgba(108, 117, 125, 0.92);
      letter-spacing: .01em; }
    @media only screen and (max-width: 767px) {
      .cardbox .cardbox-heading {
        padding: 8px; } }
  .cardbox .cardbox-body {
    position: relative;
    padding: 16px; }
    @media only screen and (max-width: 767px) {
      .cardbox .cardbox-body {
        padding: 8px; } }
  .cardbox .cardbox-footer {
    padding: 16px;
    border-top: 1px solid rgba(108, 117, 125, 0.12); }
    @media only screen and (max-width: 767px) {
      .cardbox .cardbox-footer {
        padding: 8px; } }
  .cardbox .cardbox-item {
    position: relative;
    display: block;
    min-height: 120px; }
    .cardbox .cardbox-item > .cardbox-item-text {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.35);
      margin: 0;
      color: #fff;
      padding: 8px; }
      .cardbox .cardbox-item > .cardbox-item-text a {
        color: inherit; }
    .cardbox .cardbox-item > .cardbox-item-image {
      display: block;
      width: 100%;
      height: 190px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover; }
    .cardbox .cardbox-item.cardbox-media {
      min-height: 280px;
      background-repeat: repeat;
      background-position: 50% 50%;
      background-size: cover;
      background-attachment: scroll;
      background-origin: padding-box; }
      .cardbox .cardbox-item.cardbox-media .cardbox-media-quote {
        padding: 16px;
        font-size: 35px; }
        @media only screen and (min-width: 768px) {
          .cardbox .cardbox-item.cardbox-media .cardbox-media-quote {
            font-size: 45px; } }
        .cardbox .cardbox-item.cardbox-media .cardbox-media-quote > a {
          color: inherit;
          text-decoration: none; }
        .cardbox .cardbox-item.cardbox-media .cardbox-media-quote:before {
          content: '\201C';
          display: block;
          font-size: 2em;
          line-height: 1;
          margin-top: 0.25em; }
  .cardbox.cardbox-transparent {
    background-color: transparent;
    border: 0;
    box-shadow: 0 0 0 #000; }
  .cardbox .cardbox-offset {
    position: relative;
    padding-bottom: 36px;
    z-index: 10; }
    .cardbox .cardbox-offset > .cardbox-offset-item {
      position: absolute;
      top: -24px;
      left: 15px;
      right: 15px; }
  .cardbox .cardbox-toolbar {
    position: relative;
    width: 100%;
    min-height: 64px;
    font-size: 18px;
    line-height: 64px;
    padding-left: 22px;
    z-index: 2; }
  .cardbox .cardbox-subheader {
    padding: 16px 0 16px 16px;
    line-height: .75em;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .01em;
    color: rgba(0, 0, 0, 0.54); }
    .cardbox .cardbox-subheader + .mda-list > .mda-list-item:first-child > .mda-list-item-text {
      padding-top: 16px; }
    .cardbox .cardbox-subheader + .mda-list > .mda-list-item:first-child > .mda-list-item-img,
    .cardbox .cardbox-subheader + .mda-list > .mda-list-item:first-child > .mda-list-item-icon,
    .cardbox .cardbox-subheader + .mda-list > .mda-list-item:first-child > .mda-list-item-initial {
      margin-top: 10px; }
  .cardbox .cardbox-divider {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid rgba(0, 0, 0, 0.12); }
    .cardbox .cardbox-divider + .cardbox-offset {
      margin-top: -10px; }
  .cardbox > .ui-datepicker,
  .cardbox > .ui-datepicker-responsive > .ui-datepicker {
    width: 100%;
    box-shadow: 0 0 0 #000;
    margin: 0; }
    .cardbox > .ui-datepicker > table,
    .cardbox > .ui-datepicker-responsive > .ui-datepicker > table {
      width: 100%; }
  .cardbox .editable-wrap {
    width: 100%; }
  .cardbox > .list-group > .list-group-item {
    border-left: 0;
    border-right: 0; }
    .cardbox > .list-group > .list-group-item:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .cardbox > .list-group > .list-group-item:last-child {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom: 0; }
  .cardbox > .table-responsive > .table,
  .cardbox > .table {
    margin-bottom: 0; }
  .cardbox > .table-responsive {
    border: 0; }
  .cardbox > .btn {
    border-radius: 0;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    text-align: center; }
    .cardbox > .btn:last-child {
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px; }
  .cardbox.cardbox-map {
    min-height: 280px; }
    .cardbox.cardbox-map .cardbox-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border: 0;
      background-color: transparent; }

/**
 * Google Material Colors (customization)
 */
.bg-pink-500 {
  background-color: #E91E63;
  color: #fff !important; }

.bg-purple-400 {
  background-color: #AB47BC;
  color: #fff !important; }

.bg-purple-500 {
  background-color: #9C27B0;
  color: #fff !important; }

.bg-deep-purple-500 {
  background-color: #673AB7;
  color: #fff !important; }

.bg-indigo-500 {
  background-color: #3F51B5;
  color: #fff !important; }

.bg-green-50 {
  background-color: #E8F5E9;
  color: #fff !important; }

.bg-green-500 {
  background-color: #4CAF50;
  color: #fff !important; }

.bg-blue-500 {
  background-color: #2196F3;
  color: #fff !important; }

.bg-blue-grey-900 {
  background-color: #263238;
  color: #fff !important; }

.bg-red-500 {
  background-color: #F44336;
  color: #fff !important; }

.bg-primary,
.bg-red-800, .bg-red-900,
.bg-pink-700, .bg-pink-800, .bg-pink-900,
.bg-purple, .bg-purple-500, .bg-purple-600, .bg-purple-700, .bg-purple-800, .bg-purple-900,
.bg-deep-purple, .bg-deep-purple-500, .bg-deep-purple-600, .bg-deep-purple-700, .bg-deep-purple-800, .bg-deep-purple-900, .bg-deep-purple-a400, .bg-deep-purple-a700,
.bg-indigo, .bg-indigo-500, .bg-indigo-600, .bg-indigo-700, .bg-indigo-800, .bg-indigo-900, .bg-indigo-a700, .bg-blue-800,
.bg-blue-900, .bg-light-blue-900,
.bg-cyan-900, .bg-teal-800, .bg-teal-900,
.bg-green-800, .bg-green-900,
.bg-brown, .bg-brown-500, .bg-brown-600, .bg-brown-700, .bg-brown-800, .bg-brown-900, .bg-blue-grey-600,
.bg-blue-grey-700, .bg-blue-grey-800, .bg-blue-grey-900, .bg-grey-600,
.bg-grey-700, .bg-grey-800, .bg-grey-900 {
  color: rgba(255, 255, 255, 0.97); }
  .bg-primary .text-muted,
  .bg-red-800 .text-muted, .bg-red-900 .text-muted,
  .bg-pink-700 .text-muted, .bg-pink-800 .text-muted, .bg-pink-900 .text-muted,
  .bg-purple .text-muted, .bg-purple-500 .text-muted, .bg-purple-600 .text-muted, .bg-purple-700 .text-muted, .bg-purple-800 .text-muted, .bg-purple-900 .text-muted,
  .bg-deep-purple .text-muted, .bg-deep-purple-500 .text-muted, .bg-deep-purple-600 .text-muted, .bg-deep-purple-700 .text-muted, .bg-deep-purple-800 .text-muted, .bg-deep-purple-900 .text-muted, .bg-deep-purple-a400 .text-muted, .bg-deep-purple-a700 .text-muted,
  .bg-indigo .text-muted, .bg-indigo-500 .text-muted, .bg-indigo-600 .text-muted, .bg-indigo-700 .text-muted, .bg-indigo-800 .text-muted, .bg-indigo-900 .text-muted, .bg-indigo-a700 .text-muted, .bg-blue-800 .text-muted,
  .bg-blue-900 .text-muted, .bg-light-blue-900 .text-muted,
  .bg-cyan-900 .text-muted, .bg-teal-800 .text-muted, .bg-teal-900 .text-muted,
  .bg-green-800 .text-muted, .bg-green-900 .text-muted,
  .bg-brown .text-muted, .bg-brown-500 .text-muted, .bg-brown-600 .text-muted, .bg-brown-700 .text-muted, .bg-brown-800 .text-muted, .bg-brown-900 .text-muted, .bg-blue-grey-600 .text-muted,
  .bg-blue-grey-700 .text-muted, .bg-blue-grey-800 .text-muted, .bg-blue-grey-900 .text-muted, .bg-grey-600 .text-muted,
  .bg-grey-700 .text-muted, .bg-grey-800 .text-muted, .bg-grey-900 .text-muted {
    color: rgba(255, 255, 255, 0.57) !important; }
  .bg-primary .text-soft,
  .bg-red-800 .text-soft, .bg-red-900 .text-soft,
  .bg-pink-700 .text-soft, .bg-pink-800 .text-soft, .bg-pink-900 .text-soft,
  .bg-purple .text-soft, .bg-purple-500 .text-soft, .bg-purple-600 .text-soft, .bg-purple-700 .text-soft, .bg-purple-800 .text-soft, .bg-purple-900 .text-soft,
  .bg-deep-purple .text-soft, .bg-deep-purple-500 .text-soft, .bg-deep-purple-600 .text-soft, .bg-deep-purple-700 .text-soft, .bg-deep-purple-800 .text-soft, .bg-deep-purple-900 .text-soft, .bg-deep-purple-a400 .text-soft, .bg-deep-purple-a700 .text-soft,
  .bg-indigo .text-soft, .bg-indigo-500 .text-soft, .bg-indigo-600 .text-soft, .bg-indigo-700 .text-soft, .bg-indigo-800 .text-soft, .bg-indigo-900 .text-soft, .bg-indigo-a700 .text-soft, .bg-blue-800 .text-soft,
  .bg-blue-900 .text-soft, .bg-light-blue-900 .text-soft,
  .bg-cyan-900 .text-soft, .bg-teal-800 .text-soft, .bg-teal-900 .text-soft,
  .bg-green-800 .text-soft, .bg-green-900 .text-soft,
  .bg-brown .text-soft, .bg-brown-500 .text-soft, .bg-brown-600 .text-soft, .bg-brown-700 .text-soft, .bg-brown-800 .text-soft, .bg-brown-900 .text-soft, .bg-blue-grey-600 .text-soft,
  .bg-blue-grey-700 .text-soft, .bg-blue-grey-800 .text-soft, .bg-blue-grey-900 .text-soft, .bg-grey-600 .text-soft,
  .bg-grey-700 .text-soft, .bg-grey-800 .text-soft, .bg-grey-900 .text-soft {
    color: rgba(255, 255, 255, 0.26); }

.bg-red, .bg-red-500, .bg-red-600, .bg-red-700, .bg-red-a200, .bg-red-a400, .bg-red-a700, .bg-pink,
.bg-pink-500, .bg-pink-600, .bg-pink-a200, .bg-pink-a400, .bg-pink-a700, .bg-purple-300,
.bg-purple-400, .bg-purple-a200, .bg-purple-a400, .bg-purple-a700, .bg-deep-purple-300,
.bg-deep-purple-400, .bg-deep-purple-a200,
.bg-indigo-300, .bg-indigo-400, .bg-indigo-a200, .bg-indigo-a400, .bg-blue,
.bg-blue-500, .bg-blue-600, .bg-blue-700, .bg-blue-a200, .bg-blue-a400, .bg-blue-a700, .bg-light-blue,
.bg-light-blue-500, .bg-light-blue-600, .bg-light-blue-700, .bg-light-blue-800, .bg-light-blue-a700, .bg-cyan,
.bg-cyan-500, .bg-cyan-600, .bg-cyan-700, .bg-cyan-800, .bg-teal,
.bg-teal-500, .bg-teal-600, .bg-teal-700,
.bg-green, .bg-green-500, .bg-green-600, .bg-green-700, .bg-light-green-800, .bg-light-green-900,
.bg-lime-900, .bg-orange-800, .bg-orange-900,
.bg-deep-orange, .bg-deep-orange-500, .bg-deep-orange-600, .bg-deep-orange-700, .bg-deep-orange-800, .bg-deep-orange-900, .bg-deep-orange-a400, .bg-deep-orange-a700, .bg-brown-300,
.bg-brown-400, .bg-blue-grey, .bg-blue-grey-400, .bg-blue-grey-500 {
  color: #fff; }
  .bg-red .text-muted, .bg-red-500 .text-muted, .bg-red-600 .text-muted, .bg-red-700 .text-muted, .bg-red-a200 .text-muted, .bg-red-a400 .text-muted, .bg-red-a700 .text-muted, .bg-pink .text-muted,
  .bg-pink-500 .text-muted, .bg-pink-600 .text-muted, .bg-pink-a200 .text-muted, .bg-pink-a400 .text-muted, .bg-pink-a700 .text-muted, .bg-purple-300 .text-muted,
  .bg-purple-400 .text-muted, .bg-purple-a200 .text-muted, .bg-purple-a400 .text-muted, .bg-purple-a700 .text-muted, .bg-deep-purple-300 .text-muted,
  .bg-deep-purple-400 .text-muted, .bg-deep-purple-a200 .text-muted,
  .bg-indigo-300 .text-muted, .bg-indigo-400 .text-muted, .bg-indigo-a200 .text-muted, .bg-indigo-a400 .text-muted, .bg-blue .text-muted,
  .bg-blue-500 .text-muted, .bg-blue-600 .text-muted, .bg-blue-700 .text-muted, .bg-blue-a200 .text-muted, .bg-blue-a400 .text-muted, .bg-blue-a700 .text-muted, .bg-light-blue .text-muted,
  .bg-light-blue-500 .text-muted, .bg-light-blue-600 .text-muted, .bg-light-blue-700 .text-muted, .bg-light-blue-800 .text-muted, .bg-light-blue-a700 .text-muted, .bg-cyan .text-muted,
  .bg-cyan-500 .text-muted, .bg-cyan-600 .text-muted, .bg-cyan-700 .text-muted, .bg-cyan-800 .text-muted, .bg-teal .text-muted,
  .bg-teal-500 .text-muted, .bg-teal-600 .text-muted, .bg-teal-700 .text-muted,
  .bg-green .text-muted, .bg-green-500 .text-muted, .bg-green-600 .text-muted, .bg-green-700 .text-muted, .bg-light-green-800 .text-muted, .bg-light-green-900 .text-muted,
  .bg-lime-900 .text-muted, .bg-orange-800 .text-muted, .bg-orange-900 .text-muted,
  .bg-deep-orange .text-muted, .bg-deep-orange-500 .text-muted, .bg-deep-orange-600 .text-muted, .bg-deep-orange-700 .text-muted, .bg-deep-orange-800 .text-muted, .bg-deep-orange-900 .text-muted, .bg-deep-orange-a400 .text-muted, .bg-deep-orange-a700 .text-muted, .bg-brown-300 .text-muted,
  .bg-brown-400 .text-muted, .bg-blue-grey .text-muted, .bg-blue-grey-400 .text-muted, .bg-blue-grey-500 .text-muted {
    color: rgba(255, 255, 255, 0.57) !important; }
  .bg-red .text-soft, .bg-red-500 .text-soft, .bg-red-600 .text-soft, .bg-red-700 .text-soft, .bg-red-a200 .text-soft, .bg-red-a400 .text-soft, .bg-red-a700 .text-soft, .bg-pink .text-soft,
  .bg-pink-500 .text-soft, .bg-pink-600 .text-soft, .bg-pink-a200 .text-soft, .bg-pink-a400 .text-soft, .bg-pink-a700 .text-soft, .bg-purple-300 .text-soft,
  .bg-purple-400 .text-soft, .bg-purple-a200 .text-soft, .bg-purple-a400 .text-soft, .bg-purple-a700 .text-soft, .bg-deep-purple-300 .text-soft,
  .bg-deep-purple-400 .text-soft, .bg-deep-purple-a200 .text-soft,
  .bg-indigo-300 .text-soft, .bg-indigo-400 .text-soft, .bg-indigo-a200 .text-soft, .bg-indigo-a400 .text-soft, .bg-blue .text-soft,
  .bg-blue-500 .text-soft, .bg-blue-600 .text-soft, .bg-blue-700 .text-soft, .bg-blue-a200 .text-soft, .bg-blue-a400 .text-soft, .bg-blue-a700 .text-soft, .bg-light-blue .text-soft,
  .bg-light-blue-500 .text-soft, .bg-light-blue-600 .text-soft, .bg-light-blue-700 .text-soft, .bg-light-blue-800 .text-soft, .bg-light-blue-a700 .text-soft, .bg-cyan .text-soft,
  .bg-cyan-500 .text-soft, .bg-cyan-600 .text-soft, .bg-cyan-700 .text-soft, .bg-cyan-800 .text-soft, .bg-teal .text-soft,
  .bg-teal-500 .text-soft, .bg-teal-600 .text-soft, .bg-teal-700 .text-soft,
  .bg-green .text-soft, .bg-green-500 .text-soft, .bg-green-600 .text-soft, .bg-green-700 .text-soft, .bg-light-green-800 .text-soft, .bg-light-green-900 .text-soft,
  .bg-lime-900 .text-soft, .bg-orange-800 .text-soft, .bg-orange-900 .text-soft,
  .bg-deep-orange .text-soft, .bg-deep-orange-500 .text-soft, .bg-deep-orange-600 .text-soft, .bg-deep-orange-700 .text-soft, .bg-deep-orange-800 .text-soft, .bg-deep-orange-900 .text-soft, .bg-deep-orange-a400 .text-soft, .bg-deep-orange-a700 .text-soft, .bg-brown-300 .text-soft,
  .bg-brown-400 .text-soft, .bg-blue-grey .text-soft, .bg-blue-grey-400 .text-soft, .bg-blue-grey-500 .text-soft {
    color: rgba(255, 255, 255, 0.26); }

/**
 * Float Button
 */
.floatbutton {
  position: relative;
  z-index: 1000; }
  .floatbutton .mfb-component__button--child,
  .floatbutton .mfb-component__button--main {
    color: #fff; }

/**
 * Utilities
 */
.m0 {
  margin: 0 !important; }

.ml0 {
  margin-left: 0 !important; }

.mr0 {
  margin-right: 0 !important; }

.mt0 {
  margin-top: 0 !important; }

.mb0 {
  margin-bottom: 0 !important; }

.m {
  margin: 16px !important; }

.ml, .mh {
  margin-left: 16px !important; }

.mr, .mh {
  margin-right: 16px !important; }

.mt, .mv {
  margin-top: 16px !important; }

.mb, .mv {
  margin-bottom: 16px !important; }

.m-sm {
  margin: 8px !important; }

.ml-sm, .mh-sm {
  margin-left: 8px !important; }

.mr-sm, .mh-sm {
  margin-right: 8px !important; }

.mt-sm, .mv-sm {
  margin-top: 8px !important; }

.mb-sm, .mv-sm {
  margin-bottom: 8px !important; }

.m-lg {
  margin: 24px !important; }

.ml-lg, .mh-lg {
  margin-left: 24px !important; }

.mr-lg, .mh-lg {
  margin-right: 24px !important; }

.mt-lg, .mv-lg {
  margin-top: 24px !important; }

.mb-lg, .mv-lg {
  margin-bottom: 24px !important; }

.m-xl {
  margin: 32px !important; }

.ml-xl, .mh-xl {
  margin-left: 32px !important; }

.mr-xl, .mh-xl {
  margin-right: 32px !important; }

.mt-xl, .mv-xl {
  margin-top: 32px !important; }

.mb-xl, .mv-xl {
  margin-bottom: 32px !important; }

.p0 {
  padding: 0 !important; }

.pl0, .ph0 {
  padding-left: 0 !important; }

.pr0, .ph0 {
  padding-right: 0 !important; }

.pt0, .pv0 {
  padding-top: 0 !important; }

.pb0, .pv0 {
  padding-bottom: 0 !important; }

.p {
  padding: 16px !important; }

.pl, .ph {
  padding-left: 16px !important; }

.pr, .ph {
  padding-right: 16px !important; }

.pt, .pv {
  padding-top: 16px !important; }

.pb, .pv {
  padding-bottom: 16px !important; }

.p-sm {
  padding: 4px !important; }

.pl-sm, .ph-sm {
  padding-left: 4px !important; }

.pr-sm, .ph-sm {
  padding-right: 4px !important; }

.pt-sm, .pv-sm {
  padding-top: 4px !important; }

.pb-sm, .pv-sm {
  padding-bottom: 4px !important; }

.p-lg {
  padding: 24px !important; }

.pl-lg, .ph-lg {
  padding-left: 24px !important; }

.pr-lg, .ph-lg {
  padding-right: 24px !important; }

.pt-lg, .pv-lg {
  padding-top: 24px !important; }

.pb-lg, .pv-lg {
  padding-bottom: 24px !important; }

.p-xl {
  padding: 32px !important; }

.pl-xl, .ph-xl {
  padding-left: 32px !important; }

.pr-xl, .ph-xl {
  padding-right: 32px !important; }

.pt-xl, .pv-xl {
  padding-top: 32px !important; }

.pb-xl, .pv-xl {
  padding-bottom: 32px !important; }

.fh {
  height: 100% !important; }

@media only screen and (min-width: 768px) {
  .fh-sm {
    height: 100% !important; } }

@media only screen and (min-width: 992px) {
  .fh-md {
    height: 100% !important; } }

@media only screen and (min-width: 1200px) {
  .fh-lg {
    height: 100% !important; } }

.fw {
  width: 100% !important; }

@media only screen and (min-width: 768px) {
  .fw-sm {
    width: 100% !important; } }

@media only screen and (min-width: 992px) {
  .fw-md {
    width: 100% !important; } }

@media only screen and (min-width: 1200px) {
  .fw-lg {
    width: 100% !important; } }

.va-middle {
  vertical-align: middle !important; }

.va-top {
  vertical-align: top !important; }

.va-bottom {
  vertical-align: bottom !important; }

table.va-middle td {
  vertical-align: middle !important; }

table.va-top td {
  vertical-align: top !important; }

table.va-bottom td {
  vertical-align: bottom !important; }

.b0 {
  border-width: 0 !important; }

.bl0 {
  border-left-width: 0 !important; }

.br0 {
  border-right-width: 0 !important; }

.bt0 {
  border-top-width: 0 !important; }

.bb0 {
  border-bottom-width: 0 !important; }

.br, .b, .ba {
  border-right: 1px solid rgba(108, 117, 125, 0.32); }

.bl, .b, .ba {
  border-left: 1px solid rgba(108, 117, 125, 0.32); }

.bt, .b, .ba {
  border-top: 1px solid rgba(108, 117, 125, 0.32); }

.bb, .b, .ba {
  border-bottom: 1px solid rgba(108, 117, 125, 0.32); }

.spr {
  margin-right: .25em; }

.spl {
  margin-left: .25em; }

.text-white {
  color: #fff; }

.text-black {
  color: #000; }

.text-alpha {
  color: rgba(255, 255, 255, 0.5); }

.text-alpha-inverse {
  color: rgba(0, 0, 0, 0.5); }

.text-gray-darker {
  color: #263238; }

.text-gray-dark {
  color: #455A64; }

.text-gray {
  color: #607D8B; }

.text-gray-light {
  color: #90A4AE; }

.text-gray-lighter {
  color: #e8e8e8; }

.text-inherit {
  color: inherit !important; }

.text-soft {
  color: rgba(108, 117, 125, 0.66); }

.text-light {
  color: rgba(255, 255, 255, 0.74); }

.text-sm {
  font-size: 0.69062rem; }

.text-md {
  font-size: 1.54375rem; }

.text-lg {
  font-size: 2.4375rem; }

.lh1 {
  line-height: 1; }

.text-nowrap {
  white-space: nowrap; }

.text-thin {
  font-weight: 100 !important; }

.text-bold {
  font-weight: bold !important; }

.text-normal {
  font-weight: normal !important; }

.text-normal p {
  font-weight: normal; }

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: bottom; }

.inline {
  display: inline-block !important; }

.block-center {
  margin: 0 auto; }

.block {
  display: block !important; }

.bg-white {
  background-color: #fff;
  color: inherit !important; }

.bg-primary {
  background-color: #dd972d !important;
  color: #fff !important; }

.bg-primary .text-muted {
  color: rgba(255, 255, 255, 0.5) !important; }

.bg-primary small {
  color: inherit; }

.bg-success {
  background-color: #4CAF50 !important;
  color: #fff !important; }

.bg-success .text-muted {
  color: rgba(255, 255, 255, 0.5) !important; }

.bg-success small {
  color: inherit; }

.bg-info {
  background-color: #03A9F4 !important;
  color: #fff !important; }

.bg-info .text-muted {
  color: rgba(255, 255, 255, 0.5) !important; }

.bg-info small {
  color: inherit; }

.bg-warning {
  background-color: #FFB300 !important;
  color: #fff !important; }

.bg-warning .text-muted {
  color: rgba(255, 255, 255, 0.5) !important; }

.bg-warning small {
  color: inherit; }

.bg-danger {
  background-color: #F44336 !important;
  color: #fff !important; }

.bg-danger .text-muted {
  color: rgba(255, 255, 255, 0.5) !important; }

.bg-danger small {
  color: inherit; }

.bg-gray-darker {
  background-color: #263238;
  color: #fff !important; }

.bg-gray-dark {
  background-color: #455A64;
  color: #fff !important; }

.bg-gray {
  background-color: #607D8B;
  color: #6d6e70 !important; }

.bg-gray-10 {
  background-color: #f8f9fa;
  color: #6d6e70 !important; }

.bg-gray-light {
  background-color: #90A4AE;
  color: #6d6e70 !important; }

.bg-gray-lighter {
  background-color: #e8e8e8;
  color: #6d6e70 !important; }

.bg-transparent {
  background-color: transparent !important; }

.bg-fade {
  background-color: transparent !important;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), color-stop(55%, rgba(0, 0, 0, 0.5)), to(black));
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5) 55%, black);
  background-repeat: no-repeat; }

.bg-cover {
  background-size: cover; }

.bg-full {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed; }

.bg-center {
  background-position: center center;
  background-size: cover; }

.thumb8 {
  width: 8px !important;
  height: 8px !important; }

.thumb16 {
  width: 16px !important;
  height: 16px !important; }

.thumb24 {
  width: 24px !important;
  height: 24px !important; }

.thumb32 {
  width: 32px !important;
  height: 32px !important; }

.thumb40 {
  width: 40px !important;
  height: 40px !important; }

.thumb48 {
  width: 48px !important;
  height: 48px !important; }

.thumb64 {
  width: 64px !important;
  height: 64px !important; }

.thumb80 {
  width: 80px !important;
  height: 80px !important; }

.thumb96 {
  width: 96px !important;
  height: 96px !important; }

.thumb128 {
  width: 128px !important;
  height: 128px !important; }

.initial8 {
  width: 8px !important;
  height: 8px !important;
  line-height: 8px;
  text-align: center;
  border-radius: 50%; }

.initial16 {
  width: 16px !important;
  height: 16px !important;
  line-height: 16px;
  text-align: center;
  border-radius: 50%; }

.initial24 {
  width: 24px !important;
  height: 24px !important;
  line-height: 24px;
  text-align: center;
  border-radius: 50%; }

.initial32 {
  width: 32px !important;
  height: 32px !important;
  line-height: 32px;
  text-align: center;
  border-radius: 50%; }

.initial40 {
  width: 40px !important;
  height: 40px !important;
  line-height: 40px;
  text-align: center;
  border-radius: 50%; }

.initial48 {
  width: 48px !important;
  height: 48px !important;
  line-height: 48px;
  text-align: center;
  border-radius: 50%; }

.initial64 {
  width: 64px !important;
  height: 64px !important;
  line-height: 64px;
  text-align: center;
  border-radius: 50%; }

.initial80 {
  width: 80px !important;
  height: 80px !important;
  line-height: 80px;
  text-align: center;
  border-radius: 50%; }

.initial96 {
  width: 96px !important;
  height: 96px !important;
  line-height: 96px;
  text-align: center;
  border-radius: 50%; }

.initial128 {
  width: 128px !important;
  height: 128px !important;
  line-height: 128px;
  text-align: center;
  border-radius: 50%; }

.table-fixed {
  table-layout: fixed;
  width: 100%; }

.shadow-clear {
  box-shadow: 0 0 0 #000 !important; }

.radius-clear {
  border-radius: 0 !important; }

.input-rounded {
  border-radius: 100px; }

.item-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab; }

.item-grabbing {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing; }

.clickable {
  cursor: pointer !important; }

.rel-wrapper {
  position: relative !important; }

.oh {
  overflow: hidden; }

.oa {
  overflow: auto; }

.icon-18 {
  font-size: 18px;
  vertical-align: -18%; }

.icon-24 {
  font-size: 24px;
  vertical-align: -24%; }

.icon-36 {
  font-size: 36px;
  vertical-align: -36%; }

.icon-48 {
  font-size: 48px;
  vertical-align: -48%; }

.icon-2x {
  font-size: 2em; }

.icon-3x {
  font-size: 3em; }

.icon-4x {
  font-size: 4em; }

.icon-5x {
  font-size: 5em; }

.icon-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%; }

.icon-fw {
  display: inline-block;
  width: 1.28571429em;
  text-align: center; }

.icon-dark {
  color: rgba(0, 0, 0, 0.54); }
  .icon-dark.icon-inactive {
    color: rgba(0, 0, 0, 0.26); }

.icon-light {
  color: white; }
  .icon-light.icon-inactive {
    color: rgba(255, 255, 255, 0.3); }

.wd-xxs {
  width: 60px; }

.wd-xs {
  width: 90px; }

.wd-sm {
  width: 150px; }

.wd-sd {
  width: 200px; }

.wd-md {
  width: 240px; }

.wd-lg {
  width: 280px; }

.wd-xl {
  width: 320px; }

.wd-xxl {
  width: 360px; }

.wd-wide {
  width: 100%; }

.wd-auto {
  width: auto; }

.wd-zero {
  width: 0; }

.link-unstyled {
  text-decoration: none !important;
  outline: none !important; }

textarea.no-resize {
  resize: none;
  max-width: 100%;
  min-width: 100%; }

.shadow-z0 {
  box-shadow: 0 0 0 #000; }

.shadow-z1 {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.014); }

.shadow-z2 {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.098), 0 1px 10px 0 rgba(0, 0, 0, 0.014); }

.shadow-z3 {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.14), 0 6px 10px 0 rgba(0, 0, 0, 0.098), 0 1px 18px 0 rgba(0, 0, 0, 0.014); }

.shadow-z4 {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.14), 0 8px 10px 1px rgba(0, 0, 0, 0.098), 0 3px 14px 2px rgba(0, 0, 0, 0.014); }

.shadow-z5 {
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.14), 0 16px 24px 2px rgba(0, 0, 0, 0.098), 0 6px 30px 5px rgba(0, 0, 0, 0.014); }

.image-list > a {
  margin-left: -8px; }
  .image-list > a:first-child {
    margin-left: 0; }
  .image-list > a:last-of-type {
    margin-right: 8px; }

.list-group-unstyle > .list-group-item {
  border-left: 0;
  border-right: 0; }
  .list-group-unstyle > .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .list-group-unstyle > .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }

.icons-list {
  margin: 40px auto;
  padding-left: 0;
  width: 100%;
  list-style: none;
  text-align: center;
  font-size: 1px; }
  .icons-list > li {
    position: relative;
    z-index: 0;
    display: inline-block;
    padding: 22px;
    width: 84px;
    border-radius: 4px;
    list-style: none;
    text-align: center;
    font-weight: normal;
    font-size: 32px;
    cursor: pointer; }
    .icons-list > li:hover {
      background-color: rgba(255, 255, 255, 0.9);
      color: #03A9F4;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.098), 0 1px 10px 0 rgba(0, 0, 0, 0.014);
      -webkit-transition: all .2s ease;
      transition: all .2s ease; }

.reader-block p {
  margin: 16px auto;
  font-size: 16px;
  line-height: 28px;
  font-weight: normal; }
  .reader-block p > img {
    max-width: 100%;
    margin: 32px auto; }

.box-placeholder {
  margin-bottom: 15px;
  padding: 20px;
  border: 1px dashed rgba(108, 117, 125, 0.26);
  background: transparent;
  color: inherit; }
  .box-placeholder > :last-child {
    margin-bottom: 0; }
  .box-placeholder.box-placeholder-lg {
    padding-top: 80px;
    padding-bottom: 80px; }

